import {
    Card,
    Chip,
    LinearProgress,
    Pagination,
    Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import "./track.styles.css";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { apiGateway } from "../../utils/config";
import NoImg from "../../assests/images/nodatafound.png";
import { EDIT_TRACK_ACTION, GET_ALL_TRACKS } from "../../utils/constants";
import { Button, Dropdown, Modal, Table } from "react-bootstrap";
import { getDateString } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import useZustandStore from "../../zustandStore/useZustandStore";


export default function TrackList() {
    //dispatch
    const axiosPrivate = useAxiosPrivate();
    //redux selectors
    const [errorMsg, setErrorMsg] = useState({ error: "", type: "" })
    const [pageNumber, setPageNumber] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [isDialog, setIsDialog] = useState(false)
    const [isDialogDefault, setIsDialogDefault] = useState(false)
    const [selectedTrack, setSelectTrack] = useState({})
    const internalTeamAccessGlobalState = useZustandStore((val) => val?.internalTeamAccessGlobalState);

    useEffect(() => {
        get_all_tracks()
    }, [pageNumber]);

    const get_all_tracks = () => {
        let url = `${apiGateway}${GET_ALL_TRACKS}?page=${pageNumber}`;
        setIsLoading(true);
        axiosPrivate.get(url)
        .then(({data, status}) => {
            if(data?.error_message && Object.keys(data?.error_message).length > 0){
                setErrorMsg({type: "failed", error: data?.error_message ?? "Something went wrong"})
            }else{
                setData(data?.track_list)
                setPageCount(data?.n_pages)
                setIsLoading(false);
            }
        }).catch(({response}) => {
            setIsLoading(false);
            setErrorMsg({type: "failed", error: response?.data ? response?.data?.error_message : "Something went wrong"})
        })
    }

    const handlePageChange = (e, value) => {
        setPageNumber(value);
    }

    const toggleStatus = (track_id, is_active) => {
        let formData = new FormData()
        formData.append('is_active', !is_active)
        let url = `${apiGateway}${EDIT_TRACK_ACTION}${track_id}/toggle-status/`;
        setIsLoading(true);
        axiosPrivate.post(url, formData)
        .then(({data, status}) => {
            if(status == false){
                setErrorMsg({type: "failed", error: data?.error_message ?? "Something went wrong"})
            }else{
                setIsLoading(false);
                get_all_tracks();
                setErrorMsg({type: "success", error: "Track is status changed"})
            }
        }).catch(({response}) => {
            if(response?.status == 404){
                setErrorMsg({type: "failed", error: "Url not found"})
            }else{
                setErrorMsg({type: "failed", error: response?.data ? response?.data?.error_message : "Something went wrong"})
            }
            setIsLoading(false);
        })
        setSelectTrack({});
        setIsDialog(false);
    }

    const makeDefault = (track_id) => {
        let url = `${apiGateway}${EDIT_TRACK_ACTION}${track_id}/default/`;
        setIsLoading(true);
        axiosPrivate.post(url)
        .then(({data, status}) => {
            if(status == false){
                setErrorMsg({type: "failed", error: data?.error_message ?? "Something went wrong"})
            }else{
                setIsLoading(false);
                get_all_tracks();
                setErrorMsg({type: "success", error: "Track default is updated "})
            }
        }).catch(({response}) => {
            if(response?.status == 404){
                setErrorMsg({type: "failed", error: "Url not found"})
            }else{
                setErrorMsg({type: "failed", error: response?.data ? response?.data?.error_message : "Something went wrong"})
            }
            setIsLoading(false);
        })
        setSelectTrack({});
        setIsDialogDefault(false);
    }

    return (
        <div className="experiments-list">
            {internalTeamAccessGlobalState && <div className="cta-button-create" onClick={() => {
                window.location.href = '#/track-create'
            }}>
                <AddIcon fontSize="large" className="icon-plus-cta" />
                <span>Create Track</span>
            </div>}
            
            <div>
                <AlertComponent message={errorMsg?.error} type={errorMsg?.type} setAlertNotification={() => setErrorMsg({ error: '', type: errorMsg?.type })} />
            </div>
            <div className="full-layout-scroll-enable track-list">
                <div className="mt-2">
                    <div className="card-panel creator-table">
                    <Card
                        className="shadow-table"
                        style={{ border: "1px solid var(--search_chip)" }}
                    >
                        {/* Table for displaying series data */}
                        {isLoading ? (
                        <LinearProgress color="inherit" />
                        ) : (
                        <div style={{ height: 4, background: "white" }}></div>
                        )}
                        <Table hover responsive className="table-series">
                        <thead style={{ position: "sticky", zIndex: 0 }}>
                            <tr>
                            <th>Id</th>
                            <th className="title-experiment-row">Title</th>
                            <th>Created on</th>
                            <th>Updated on</th>
                            <th>Status</th>
                            <th>Default</th>
                            <th>A/B</th>
                            <th>Onboarding</th>
                            <th>Score</th>
                            <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.length > 0
                            && data?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                    <td>{item?.id}</td>
                                    <td>{item?.title}</td>
                                    <td>
                                        {getDateString(
                                        new Date(item?.created_on)
                                            .toISOString()
                                            .split("T")[0]
                                        )}
                                    </td>
                                    <td>
                                        {getDateString(
                                        new Date(item?.updated_on)
                                            .toISOString()
                                            .split("T")[0]
                                        )}
                                    </td>
                                    <td>
                                        <Chip
                                            label={item?.is_active ? 'Active' : 'InActive'}
                                            className={item?.is_active ? 'track-active' : 'track-in_active'}
                                        />
                                    </td>
                                    <td>
                                        <Chip
                                            label={item?.is_default ? 'Default' : 'No'}
                                            className={item?.is_default ? 'track-default' : 'track-in_active'}
                                        />
                                    </td>
                                    <td>
                                        <Chip
                                            label={item?.is_experiment ? 'Yes' : 'No'}
                                            className={item?.is_experiment ? 'track-active' : 'track-in_active'}
                                        />
                                    </td>
                                    <td>
                                        <Chip
                                            label={item?.onboarding_status ? 'Yes' : 'No'}
                                            className={item?.onboarding_status ? 'track-active' : 'track-in_active'}
                                        />
                                    </td>
                                    <td>{item?.score}</td>
                                    <td>
                                        <Dropdown className="w-100">
                                            <Dropdown.Toggle className="dropdown-action">
                                            <FontAwesomeIcon
                                                className="rejection-logs ml-1"
                                                icon={faEllipsisV}
                                                size="1x"
                                            />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="action-menu">
                                                <Dropdown.Item
                                                    className="action-menu-item"
                                                    style={{padding: 0}}
                                                    href={`#/track-edit/${item?.id}`}
                                                >
                                                    View details
                                                </Dropdown.Item> 
                                                {internalTeamAccessGlobalState && <div>
                                                {!item?.is_default && <Dropdown.Item
                                                    className="action-menu-item"
                                                    onClick={() => {
                                                        setSelectTrack(item);
                                                        setIsDialogDefault(true);
                                                    }}
                                                >
                                                    Make Default
                                                </Dropdown.Item>}
                                                </div>}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                    </tr>
                                );
                                })
                            }
                        </tbody>
                        </Table>
                        {!isLoading && data?.length == 0 && (<div className="img-nofound-wrapper"><img src={NoImg} className="img-nofound" alt="No data img" /></div>)}
                    </Card>
                    <div className="pagination-table-bottom">
                        <Pagination count={pageCount} page={pageNumber} onChange={(e, val) => handlePageChange(e, val)}variant="outlined" shape="rounded" />
                    </div>
                    </div>
                </div>
            </div>
            <Modal show={isDialog} centered>
                <Modal.Header closeButton onHide={() => {
                    setIsDialog(false);
                    setSelectTrack({});
                }}>
                    Track - {selectedTrack?.title}
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <b>Update track status to {selectedTrack?.is_active ? "InActive" : "Active"}</b>
                        <br/>
                        Want to proceed with changes?
                    </p>
                    <div>
                        <Button className="button-custom" disabled={isLoading} onClick={() => {
                            toggleStatus(selectedTrack?.id, selectedTrack?.is_active);
                        }}>
                            {isLoading ? "Loading..." : 
                            selectedTrack?.is_active ? "InActive" : "Active"}
                        </Button>
                        <Button className="button-custom-cancel" onClick={() => {
                            setIsDialog(false)
                            setSelectTrack({})
                        }}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={isDialogDefault} centered>
                <Modal.Header closeButton onHide={() => {
                    setIsDialogDefault(false);
                    setSelectTrack({});
                }}>
                    Track - {selectedTrack?.title}
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <b>{!selectedTrack?.is_default ? "Make" : "Remove"} this track as default</b>
                        <br/>
                        Want to proceed with changes?
                    </p>
                    <div>
                        <Button className="button-custom" disabled={isLoading} onClick={() => {
                            makeDefault(selectedTrack?.id);
                        }}>
                            {isLoading ? "Loading..." : 
                            !selectedTrack?.is_default ? "Make Default" : "Remove Default"}
                        </Button>
                        <Button className="button-custom-cancel" onClick={() => {
                            setIsDialogDefault(false)
                            setSelectTrack({})
                        }}>
                            Cancel
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

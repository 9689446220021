import React from "react";
import { handleCopyTextToClipboard } from "../../utils/utils";

// Common component which copies series id onClick
const SeriesIdCopy = ({ seriesId, className, setMessage }) => {
  return (
    <button
      className={`${className}`}
      onClick={() => {
        handleCopyTextToClipboard({ clipboardText: seriesId });
        setMessage({ type: "success", error: "Series ID Copied" });
      }}
    >
      #{seriesId}
    </button>
  );
};

export default SeriesIdCopy;

import React from "react";
import { handleFormatDateWithSlash } from "../../utils/utils";
import { handleSeriesStatusColor } from "../../utils/constants/VideoListing";
import {
  handleFormatMetrics,
  handleFormatPayout,
  handleFormatViews,
} from "../../utils/constants/Dashboard";
import placeholder from "../../assests/images/placeholder1.png";

const SeriesStats = ({ data }) => {
  const stats = data?.stats;
  return (
    <div className="!bg-white rounded-xl p-3">
      {/* Image, Title, Date, Views, Status & Ratings */}
      <div className="flex gap-x-3">
        <img
          src={data?.image || placeholder}
          alt={data?.title}
          className="w-[127px] h-[180px] rounded-md"
        />
        <div>
          <h6 className="text-[16px] font-medium max-w-[210px] line-clamp-3 leading-5">
            {data?.title}
          </h6>
          <div className="text-[12px] text-gray-500 flex gap-x-1 mt-2">
            {data?.approved_on ? (
              <p>
                {handleFormatDateWithSlash({
                  dateString: data?.approved_on,
                })}
              </p>
            ) : (
              "-"
            )}{" "}
            •{data?.duration_m ? <p>{data?.duration_m} mins</p> : " -"}
            {stats?.rating && (
              <p>
                • <span className="!text-[#F4B513]">★ {stats?.rating}</span>
              </p>
            )}
          </div>

          {stats?.status && (
            <div
              className={` text-[13px] capitalize rounded-full w-fit px-2 py-1 mt-3 ${handleSeriesStatusColor(
                { status: stats?.status }
              )} `}
            >
              {stats?.status}
            </div>
          )}
        </div>
      </div>

      {/* Views, WatchTime & Completion rate */}
      <div className="text-[12px] mt-3">
        <div className="flex justify-between mt-[2px]">
          <p className="text-gray-500">Views</p>
          {stats?.n_views ? (
            <p>{handleFormatViews({ views: stats?.n_views })}</p>
          ) : (
            "-"
          )}
        </div>

        <div className="flex justify-between mt-[2px]">
          <p className="text-gray-500">Watch Time</p>
          {stats?.watch_hours ? (
            <p>{`${handleFormatMetrics({
              num: stats?.watch_hours || 0,
              isGreaterThen10K: true,
            })} hours`}</p>
          ) : (
            "-"
          )}
        </div>

        <div className="flex justify-between mt-[2px]">
          <p className="text-gray-500">Completion Rate</p>
          <p>
            {stats?.completion_rate ? (
              <span className="font-semibold">{stats?.completion_rate}%</span>
            ) : (
              ""
            )}
            <span className="text-gray-500">
              {stats?.target_completion_rate
                ? `(${stats?.target_completion_rate}%)`
                : "-"}
            </span>
          </p>
        </div>
      </div>

      <hr className="mt-3" />

      {/* Fixed, Variable & TotalPayout  */}
      <div className="text-[12px] mt-3">
        {/* <div className="flex justify-between mt-[2px] text-gray-500">
          <p className="font-medium">Fixed</p>
          {stats?.fixed ? <p>₹{stats?.fixed}</p> : "-"}
        </div>

        <div className="flex justify-between mt-[2px] text-gray-500">
          <p className="font-medium">Variable</p>
          {stats?.variables ? <p>₹{stats?.variables}</p> : "-"}
        </div> */}

        <div className="flex justify-between mt-[2px]">
          <p className="text-gray-500">Total Payout</p>
          {stats?.payout ? (
            <p className="text-[16px] font-medium">{`₹${handleFormatPayout({
              totalPayout: stats?.payout || 0,
            })}`}</p>
          ) : (
            "-"
          )}
        </div>
      </div>
    </div>
  );
};

export default SeriesStats;

import React, { useState } from "react";
import { handleDefaultProfile } from "../../../utils/utils";
import { logout, setCurrentUser } from "../../../redux/user/user.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { handleDisplayRole } from "../../../utils/constants/Team";
import { ChevronLeftRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CustomModal from "../CustomModal";
import SeekhoWhiteLogo from "../../SideNavigation/Icons/seekho-white.png";
import LogoutIcon from "@mui/icons-material/Logout";
import MobileSideDrawer from "./MobileSideDrawer";
import useZustandStore from "../../../zustandStore/useZustandStore";
import CustomBreadcrumbs from "../CustomBreadCrumb";

const Navbar = ({ currentUser }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSideDrawer, setOpenSideDrawer] = useState(false);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const { showSideNavigationBar, setShowSideNavigationBar } = useZustandStore();
  const open = anchorEl;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOnMyProfile = () => {
    setAnchorEl(null);
    window.location.href = "#/profile";
  };

  const history = useHistory();

  const handleBack = () => {
    history.goBack(); // This works like router.back() in Next.js
  };

  return (
    <div>
      <div className="!px-3 bg-black flex justify-between !py-4 items-center fixed w-full lg:!px-2 lg:!py-1 z-[999]">
        {/* Logo, sidebar toggle and breadcrumbs */}
        <div className="flex items-center gap-x-16">
          <div className="flex items-center gap-x-6">
            <Tooltip
              title={showSideNavigationBar ? "Hide Sidebar" : "Expand Sidebar"}
              className="!hidden lg:!block"
            >
              <IconButton>
                <FontAwesomeIcon
                  icon={faBars}
                  className="text-white cursor-pointer text-[20px] hidden lg:block"
                  onClick={() =>
                    setShowSideNavigationBar(!showSideNavigationBar)
                  }
                />
              </IconButton>
            </Tooltip>

            <img src={SeekhoWhiteLogo} alt="seekho" className="w-[100px]" />
          </div>

          <div className="hidden lg:flex lg:gap-x-3 lg:items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="!text-white text-[14px] mt-1 cursor-pointer"
              onClick={handleBack}
            />
            <CustomBreadcrumbs />
          </div>
        </div>

        {/* Profile button with DropDown Menu */}
        <div className="hidden lg:block">
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img
              src={
                currentUser?.avatar ||
                handleDefaultProfile({
                  userName: currentUser?.name,
                })
              }
              alt={currentUser?.name}
              className="w-[32px] h-[32px] rounded-full object-contain hover:animate-pulse"
            />
            <ChevronLeftRounded className="-rotate-90 text-white" />
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem className="italic w-[180px] hover:!bg-transparent !-mt-2">
              <span className="text-baseBlueColor font-semibold">Hey</span>
            </MenuItem>
            <MenuItem className="!py-0 hover:!bg-transparent">
              <div className="text-[14px] font-bold line-clamp-1 border-b border-gray-300 ">
                {currentUser?.name}
              </div>
            </MenuItem>
            <MenuItem className="mt-2 py-0 bg-white">
              <div
                className="flex gap-1 max-w-[180px] overflow-x-auto"
                id="HideScroll"
              >
                {currentUser?.roles?.map((item, index) => (
                  <span
                    key={index}
                    className="text-[10px] border px-2 rounded-full bg-gray-200 text-gray-700"
                  >
                    {handleDisplayRole({ role: item })}
                  </span>
                ))}
              </div>
            </MenuItem>
            <MenuItem
              onClick={handleClickOnMyProfile}
              className="border-t mt-2"
            >
              <hr className="border" />
              <div className="flex gap-x-3 items-center ">
                <div className="w-[20px]">
                  <FontAwesomeIcon icon={faUser} />
                </div>
                <p>My Profile</p>
              </div>
            </MenuItem>
            <MenuItem
              className="mt-2"
              onClick={() => {
                setAnchorEl(null);
                setOpenLogoutModal(true);
              }}
            >
              <div className="flex gap-x-3 items-center ">
                <LogoutIcon />
                <p>Logout</p>
              </div>
            </MenuItem>
          </Menu>
        </div>

        <div className="flex items-center gap-x-5 lg:!hidden">
          <FontAwesomeIcon
            icon={faBars}
            className="text-white text-[20px] "
            onClick={() => setOpenSideDrawer(true)}
          />
        </div>

        {/* Logout confirmation */}
        <CustomModal
          title="Logout Seekho CMS"
          handleConfirmationBtnText="Logout"
          show={openLogoutModal}
          isLoading={isLoading}
          onHide={() => setOpenLogoutModal(false)}
          handleConfirmation={() => {
            setIsLoading(true);
            logout(setCurrentUser, setOpenLogoutModal, setIsLoading, "");
          }}
        >
          Are you sure you want to logout?
        </CustomModal>
      </div>

      {/* Mobile Sidebar */}
      <div className="lg:hidden">
        <MobileSideDrawer
          openSideDrawer={openSideDrawer}
          currentUser={currentUser}
          setOpenSideDrawer={setOpenSideDrawer}
          setOpenLogoutModal={setOpenLogoutModal}
        />
      </div>
    </div>
  );
};

export default Navbar;

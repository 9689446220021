import React from "react";
import CreatorDash from "../../components/CreatorDash";

const Dashboard = () => {
  return (
    <div className="mt-3">
      <CreatorDash />
    </div>
  );
};

export default Dashboard;

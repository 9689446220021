import React, { useState } from "react";
import { errorState } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { handleRenderTableHeadingWithSortIcons } from "../../utils/constants/Feedback";
import { handleFormatDate } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import SeriesIdCopy from "../Common/SeriesIdCopy";
import AlertComponent from "../Alert-Messages/alert-component.component";
import FeedbackWithProfile from "../Common/FeedbackWithProfile";
import useZustandStore from "../../zustandStore/useZustandStore";

const UIListing = ({ data }) => {
  const [message, setMessage] = useState(errorState);
  const [expandedFeedback, setExpandedFeedback] = useState({});
  const globalRoles = useZustandStore((val) => val?.globalRoles);
  const isCreator = globalRoles?.creator;

  const history = useHistory();

  // Function to toggle the read more state for each feedback
  const handleToggleReadMore = (index) => {
    setExpandedFeedback((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div>
      <table className="min-w-full text-left text-[#333333]">
        <thead className="rounded-md bg-gray-50">
          <tr>
            {handleRenderTableHeadingWithSortIcons({ history, isCreator }).map(
              (item, index) => (
                <th
                  className={` px-4 py-3 !text-[13px] !font-medium whitespace-nowrap ${
                    index === 0 ? "!min-w-[500px] lg:w-[60%]" : ""
                  }`}
                  key={item?.id}
                >
                  <div className="flex gap-x-1 items-center">
                    <span>{item?.headline}</span>
                    <button onClick={item?.onClick}>{item?.sortIcon}</button>
                  </div>
                </th>
              )
            )}
          </tr>
        </thead>

        <tbody>
          {data?.map((item, index) => {
            return (
              <tr
                className="text-black !font-normal text-[12px] !border-b last:border-none"
                key={`${item?.id}${index}`}
              >
                {/* User profile and feedback */}
                <td className="px-4 py-3">
                  <FeedbackWithProfile
                    index={index}
                    review={item?.review}
                    updatedOn={item?.updated_on}
                    avatar={item?.profile?.avatar}
                    userName={item?.profile?.name}
                    expandedFeedback={expandedFeedback}
                    handleToggleReadMore={() => handleToggleReadMore(index)}
                  />
                </td>

                {!isCreator && (
                  <td
                    className={`px-4 py-3 text-[12px] ${
                      item?.is_reported ? "text-red-500" : "text-green-500"
                    }`}
                  >
                    {item?.is_reported ? "Yes" : "No"}
                  </td>
                )}

                {/* Ratings / Like Dislike */}
                <td className="text-[16px] text-center px-4 py-3">
                  {item?.feedback_type === "like" ? (
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className="text-green-500"
                    />
                  ) : item?.feedback_type === "dislike" ? (
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      className="text-red-500"
                    />
                  ) : (
                    "-"
                  )}
                </td>

                {/* Series published date */}
                <td className="text-[13px] text-left text-gray-500 px-4 py-3 min-w-[110px]">
                  {handleFormatDate({
                    dateInString: item?.series?.approved_on,
                  })}
                </td>

                {/* Series thumbnail */}
                <td className="py-2">
                  <img
                    src={item?.series?.image}
                    alt={item?.series?.title}
                    className="w-[55px] h-[74px] mx-auto rounded-sm"
                  />
                </td>

                {/* Series title and Id */}
                <td className="text-[13px] text-gray-500 px-4 py-3 !min-w-[300px] break-words">
                  <p className="break-words line-clamp-3 tracking-normal">
                    {item?.series?.title}
                  </p>
                  <SeriesIdCopy
                    seriesId={item?.series?.id}
                    setMessage={setMessage}
                    className="!border border-gray-500 px-3 py-[1px] text-gray-500 rounded-[24px] text-[11px] mt-1 hover:bg-baseFillColor"
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Alert */}
      <AlertComponent
        message={message?.error}
        type={message?.type}
        setAlertNotification={() => setMessage({ error: "", type: "failed" })}
      />
    </div>
  );
};

export default UIListing;

import React from "react";
import "./shows.styles.css";
import ShowsList from "../../components/ShowsList/shows-list.component";

export default function ShowsPage() {
  return (
    <div style={{ overflowY: "hidden" }} className="mt-3">
      <ShowsList />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Drawer,
  LinearProgress,
  Pagination,
  Tab,
  Tabs,
} from "@mui/material";
import { selectConfigFilters } from "../../redux/config/config.selector";
import { GET_SHOWS_LIST_V1, GET_SHOW_ACTION_V1 } from "../../utils/constants";
import { Button, Dropdown, Form, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faFilter,
  faImage,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { apiGateway } from "../../utils/config";
import { useSelector } from "react-redux";
import {
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleClearAllFiltersFromURL,
  handleDeleteQueryFromUrl,
  handleFormatNumberOfFollowers,
  handleRemoveParticularFilter,
} from "../../utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import NoImg from "../../assests/images/music-guitar-illustration.svg";
import CreateShow from "../Create-Show/create-show.component";
import NoImageFound from "../Common/NoImageFound";
import useZustandStore from "../../zustandStore/useZustandStore";
import QueryValueChip from "../Common/QueryValueChip";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const quickRowFilters = [
  {
    id: "ALL",
    key: "SHOWS",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "DRAFT",
    key: "DRAFT",
    value: "draft",
    index: 1,
    show: true,
  },
  {
    id: "LIVE",
    key: "LIVE",
    value: "live",
    index: 2,
    show: true,
  },
  {
    id: "DELETED",
    key: "DELETED",
    value: "deleted",
    index: 3,
    show: true,
  },
];

export default function ShowsList() {
  //dispatch
  const axiosPrivate = useAxiosPrivate();
  //redux selectors
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "" });
  const [pageCount, setPageCount] = useState(1);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const [selectedShow, setSelectShow] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [searchQueryTag, setSearchQueryTag] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { globalRoles, internalTeamAccessGlobalState } = useZustandStore();
  const configFilters = useSelector(selectConfigFilters);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamFilters = searchParams?.getAll("filter") || [];
  const isAdmin = globalRoles?.admin;
  const isCreator = globalRoles?.creator;
  const isSuperAdmin = globalRoles?.superAdmin;
  const isEditor = globalRoles?.editor;
  const hasAccessToActions = internalTeamAccessGlobalState;
  const hasAccessToCreate = internalTeamAccessGlobalState || isCreator;

  const handleGetAllShows = async ({
    currentActiveTab = null,
    passedId,
    query,
    pageArg,
  }) => {
    setIsLoading(true);
    try {
      let state = quickRowFilters.filter(
        (d) => d.index === currentActiveTab
      )?.[0]?.value;

      // Filtering out the category with the passed id, used while removing the added filteredTags
      const filteredCategories = searchParamFilters.filter(
        (category) => category?.split("-")[1] !== passedId
      );

      // Prepare query parameters for categories
      const categoryParams = filteredCategories
        .map((item) => `category_ids=${Number(item?.split("-")?.[1])}`)
        .join("&");

      let url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=${
        pageArg || pageNumber
      }&state=${state}&q=${query}`;

      // Add category if they exist
      if (categoryParams) {
        url += `&${categoryParams}`;
      }
      const response = await axiosPrivate.get(url);
      const { data, status } = response;
      if (
        status !== 200 ||
        (data?.error_message && Object.keys(data?.error_message).length > 0)
      ) {
        setErrorMsg({
          type: "failed",
          error: data?.error_message ?? "Something went wrong",
        });
      } else {
        setData(data?.show_list);
        setPageCount(data?.n_pages);
        setIsLoading(false);
      }
    } catch (error) {
      const response = error.response;
      if (response?.status == 404) {
        setErrorMsg({ type: "failed", error: "Url not found" });
      } else {
        setErrorMsg({
          type: "failed",
          error: response?.data
            ? response?.data?.error_message
            : "Something went wrong",
        });
      }
      setIsLoading(false);
    }
  };

  const handleChangeTabs = (event, newValue) => {
    let state = quickRowFilters.filter((d) => d.index === newValue)?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          setIsLoading(false);
          handleGetAllShows({
            currentActiveTab: searchParamTabState,
            pageArg: 1,
            query: searchParamSearchQuery,
          });
          setErrorMsg({ type: "success", error: "Show Status Changed!!!" });
        }
      })
      .catch(({ response }) => {
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "error",
            error: response?.data?.error_message || response?.data?.message,
          });
        }
        setIsLoading(false);
      });
    setSelectShow({});
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created) => {
    if (isDialogCreate && show_created) {
      handleGetAllShows({
        currentActiveTab: searchParamTabState,
        pageArg: 1,
        query: searchParamSearchQuery,
      });
    }
    setIsDialogCreate((prev) => !prev);
  };

  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
      setSearchQueryTag(searchQuery);
    }
  };

  const handleSelectedFilter = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevSelectedFilters) => {
      if (checked) {
        // Add the selected filter
        return [...prevSelectedFilters, value];
      } else {
        // Remove the unselected filter
        return prevSelectedFilters.filter((filter) => filter !== value);
      }
    });
  };

  const handleApplyFilters = () => {
    handleAppendFiltersToUrl({ filters: selectedFilters, history });
    setOpenFilterDrawer(false);
  };

  const handleRemoveFilter = ({ filterToRemove }) => {
    handleRemoveParticularFilter({ filterToRemove, history });
  };

  useEffect(() => {
    handleGetAllShows({
      currentActiveTab: searchParamTabState,
      query: searchQuery || searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [
    searchParamSearchQuery,
    searchParamTabState,
    searchParamPage,
    searchParamFilters?.length,
  ]);

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  return (
    <div className="experiments-list h-[100vh] overflow-y-auto" id="HideScroll">
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />

      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={(e) => toggleCreate(e, false)}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Show</span>
        </div>
      )}

      <div className="flex w-[70%]">
        <div className="creator-search serieslist-search-col custom-searchbox w-[50%]">
          <input
            label="Search"
            type="search"
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={handleInputKey}
            value={searchQuery}
            placeholder="Enter Show Name or ID..."
            className="search-box-input-serieslist bg-white  w-100"
          />
          <FontAwesomeIcon
            className="search-icon bg-white pb-1"
            icon={faSearch}
            color="black"
            size="2x"
            onClick={() => {
              setSearchQueryTag(searchQuery);
              handleGetAllShows({
                currentActiveTab: searchParamTabState,
                query: searchParamSearchQuery,
                page: 1,
              });
            }}
          />
        </div>

        <Button
          size="small"
          variant="contained"
          className="filters-button custom-filter-button text-[14px] font-bold"
          onClick={() => {
            setOpenFilterDrawer((prev) => !prev);
            setSelectedFilters(searchParamFilters);
          }}
        >
          <FontAwesomeIcon icon={faFilter} /> FILTERS
        </Button>
      </div>

      <div className="flex gap-x-2 flex-wrap">
        {searchParamFilters?.map((item) => {
          return (
            <>
              <div key={item}>
                <QueryValueChip
                  label={item?.split("-")?.[0]}
                  onDelete={() => {
                    handleRemoveFilter({ filterToRemove: item });
                  }}
                />
              </div>
            </>
          );
        })}

        {searchQueryTag || searchParamSearchQuery ? (
          <QueryValueChip
            sName="my-2 p-1 !bg-[#b1aaed] !text-white"
            label={searchQueryTag || searchParamSearchQuery}
            onDelete={() => {
              setSearchQuery("");
              setSearchQueryTag("");
              handleDeleteQueryFromUrl({
                history,
                queryNames: ["query"],
              });
            }}
          />
        ) : null}
      </div>

      <div className="full-layout-scroll-enable track-list" id="HideScroll">
        <div className="mt-2">
          <div className="card-panel creator-table">
            <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
              <Tabs
                value={searchParamTabState}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
              >
                {quickRowFilters?.map((data, i) => (
                  <Tab label={data?.id} {...a11yProps(i)} key={i} />
                ))}
              </Tabs>
            </Box>
            <Card
              className="shadow-table !h-[77vh]"
              style={{
                border: "1px solid var(--search_chip)",
                height: "77vh",
              }}
              id="CustomScroll"
            >
              {/* Table for displaying series data */}
              {isLoading ? (
                <LinearProgress color="inherit" />
              ) : (
                <div style={{ height: 4, background: "white" }}></div>
              )}
              <Table hover responsive className="table-series">
                <thead style={{ position: "sticky", zIndex: 1000 }}>
                  <tr>
                    <th>Id</th>
                    <th>Icon</th>
                    <th>Banner Image</th>
                    <th className="title-experiment-row">Title</th>
                    <th>Category</th>
                    <th>Creator</th>
                    <th>Followers</th>
                    <th>Status</th>
                    {/* hiding action for viewer */}
                    {hasAccessToActions && <th>Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 &&
                    data?.map((item, index) => {
                      return (
                        <tr
                          key={index}
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.location.href = `#/shows-details/${item?.slug}`;
                          }}
                        >
                          <td>{item?.id}</td>
                          <td>
                            {item?.image ? (
                              <div className="series-image shows-image-icon">
                                <div className="img-hover-play">
                                  <img
                                    src={
                                      item.image ? item.original_image : faImage
                                    }
                                    alt="avatar"
                                    className="table-row-show-thumbnail series-avatar"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="w-[60px] text-center">
                                <NoImageFound displayText="No Icon" />
                              </div>
                            )}
                          </td>
                          <td>
                            {item?.banner_image ? (
                              <img
                                src={item?.banner_image}
                                alt="bannerImage"
                                className="w-[100px]"
                              />
                            ) : (
                              <div className="w-[100px] text-center">
                                <NoImageFound displayText="No Banner Image" />
                              </div>
                            )}
                          </td>

                          <td>{item?.title}</td>
                          <td>{item?.category?.title ?? "--"}</td>
                          <td>{item?.creator?.name ?? "--"}</td>
                          <td>
                            {handleFormatNumberOfFollowers({
                              numberOf: item?.n_followers,
                            })}
                          </td>
                          <td className="status">
                            <div className="flex-status-chip">
                              <div className={item?.status?.toLowerCase()}>
                                {item?.status?.toUpperCase()}
                              </div>
                            </div>
                          </td>

                          {/* hiding action for viewer */}
                          {hasAccessToActions && (
                            <td>
                              <Dropdown
                                className="w-100"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <Dropdown.Toggle className="dropdown-action">
                                  <FontAwesomeIcon
                                    className="rejection-logs ml-1"
                                    icon={faEllipsisV}
                                    size="1x"
                                  />
                                </Dropdown.Toggle>

                                {/* Hiding the live access for Editor */}
                                <Dropdown.Menu className="action-menu">
                                  {(isAdmin ||
                                    isSuperAdmin ||
                                    (isEditor && item?.status === "live") ||
                                    item?.status === "deleted") && (
                                    <Dropdown.Item
                                      className="action-menu-item"
                                      onClick={() => {
                                        setSelectShow(item);
                                        setDialogStatus({
                                          open: true,
                                          type:
                                            item?.status == "draft"
                                              ? "live"
                                              : "draft",
                                          msg:
                                            item?.status == "draft"
                                              ? "Make this show live"
                                              : "Move this show to draft",
                                        });
                                      }}
                                    >
                                      {item?.status === "draft"
                                        ? "Make Live"
                                        : "Move to Draft"}
                                    </Dropdown.Item>
                                  )}

                                  {item?.status === "draft" && (
                                    <Dropdown.Item
                                      className="action-menu-item"
                                      onClick={() => {
                                        setSelectShow(item);
                                        setDialogStatus({
                                          open: true,
                                          type: "deleted",
                                          msg: "Delete this show",
                                        });
                                      }}
                                    >
                                      Delete Show
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {!isLoading && data?.length == 0 && (
                <div className="img-nofound-wrapper flex-col">
                  <img
                    src={NoImg}
                    className="img-nofound w-[250px]"
                    alt="No data img"
                  />
                  <p className="-mt-10">No Shows to display</p>
                </div>
              )}
            </Card>
            <div className="pagination-table-bottom">
              <Pagination
                count={pageCount}
                page={searchParamPage || pageNumber}
                onChange={(e, val) => handlePageChange(e, val)}
                variant="outlined"
                shape="rounded"
              />
            </div>
          </div>
        </div>
      </div>

      {openFilterDrawer && (
        <Drawer
          anchor="right"
          open={openFilterDrawer}
          onClose={() => setOpenFilterDrawer(false)}
        >
          <Box
            sx={{ width: 350, overflow: "hidden", paddingLeft: 1 }}
            role="presentation"
          >
            <div className="filters-series box-filter-drawer">
              <div className="categories-list-series">
                <h5 className="filter-header">Categories</h5>
                <Form.Group
                  className="filters-list-series custom-drawer-list-series"
                  as={Row}
                >
                  {configFilters.categories?.map((item, index) => {
                    const filterValue = `${item.title}-${item.id}`;
                    return (
                      <Form.Check
                        key={item.id + `${index}`}
                        type="checkbox"
                        name="categoryFilter"
                        onChange={handleSelectedFilter}
                        value={filterValue}
                        label={item.title}
                        checked={selectedFilters.includes(filterValue)}
                        className="form-col col-md-6 form-checkbox-align"
                      />
                    );
                  })}
                </Form.Group>
              </div>
            </div>
            <div className="action-button-filter flex gap-x-4">
              <Button
                variant="contained"
                onClick={() => {
                  handleClearAllFiltersFromURL({ history });
                  setOpenFilterDrawer(false);
                }}
                className="apply-button text-white"
              >
                Clear all
              </Button>

              {openFilterDrawer && (
                <Button
                  variant="contained"
                  onClick={handleApplyFilters}
                  className="apply-button text-white"
                >
                  Apply
                </Button>
              )}
            </div>
          </Box>
        </Drawer>
      )}

      <Modal show={dialogStatus?.open} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setDialogStatus({ open: false });
            setSelectShow({});
          }}
        >
          {selectedShow?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{dialogStatus.msg}</b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                toggleStatus(selectedShow?.slug, dialogStatus?.type);
              }}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setDialogStatus({ open: false });
                setSelectShow({});
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* CREATE FORM MODAL */}
      <Modal show={isDialogCreate} centered>
        <Modal.Header closeButton onHide={toggleCreate}>
          <div className="modal-video-title">Create Show</div>
        </Modal.Header>
        <Modal.Body>
          <CreateShow toggleCreate={toggleCreate} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

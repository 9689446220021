import React from "react";

const ShowPillsSkeleton = () => {
  return (
    <div
      className="flex items-end max-w-screen !overflow-x-auto gap-x-2"
      id="HideScroll"
    >
      {/* Skeleton for 'All Show' button */}
      <div className="border min-w-fit px-4 py-2 text-[12px] rounded-[34px] bg-gray-200 h-[32px] w-[100px]"></div>

      {/* Skeleton pills for shows */}
      {Array(3)
        .fill("")
        .map((_, index) => (
          <div
            key={index}
            className="border min-w-fit px-4 py-2 text-[12px] rounded-[34px] bg-gray-200 h-[32px] w-[120px]"
          ></div>
        ))}
    </div>
  );
};

export default ShowPillsSkeleton;

import React from "react";
import { Dropdown } from "react-bootstrap";
import { handleDefaultProfile } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Chip } from "@mui/material";
import { handleBGColorForRoles, TEAM_ROLES } from "../../utils/constants/Team";

const TeamTable = ({ data, handleClickedEdit, handleClickedDelete }) => {
  const categoryColumnHeadings = [
    "Profile Icon",
    "Name",
    "Phone Number",
    "Signed Up On",
    "Current Role",
    "Action",
  ];

  return (
    <div>
      <div className="!relative !overflow-x-auto h-[calc(100vh-140px)] rounded-md !border border-[#534f8f] bg-white">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-gray-700 bg-white border-b">
            <tr>
              {categoryColumnHeadings.map((item) => {
                return (
                  <th
                    scope="col"
                    className="px-6 py-3 !text-[13px] bg-white z-[999] whitespace-nowrap"
                    key={item}
                  >
                    {item}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr
                  className="hover:!bg-[#e0deff] text-black cursor-pointer !font-normal text-[12px]"
                  key={`${item?.id}${index}`}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <img
                      src={
                        item?.avatar ||
                        handleDefaultProfile({
                          userName: item?.name || "Seekho User",
                        })
                      }
                      alt={item.title}
                      className="!w-[40px] h-[40px] rounded-full object-contain"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-[15px]">
                    {item?.name || "Seekho User"}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">{item?.phone}</td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    {item?.signedup_on}
                  </td>

                  <td class="px-6 py-4 whitespace-nowrap">
                    <Chip
                      label={
                        item?.roles?.includes(TEAM_ROLES.superAdmin)
                          ? "Super Admin"
                          : item?.roles
                      }
                      variant="filled"
                      className={`px-2 block w-[100px] text-white capitalize ${handleBGColorForRoles(
                        { role: item?.roles }
                      )} `}
                    />
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle className="dropdown-action-common">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1 !cursor-pointer"
                          icon={faEllipsisV}
                          size="1x"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="action-menu">
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() => {
                            handleClickedEdit({ item });
                          }}
                        >
                          Edit
                        </Dropdown.Item>

                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() => {
                            handleClickedDelete({
                              id: item?.id,
                            });
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamTable;

import React, { useEffect, useRef, useState } from "react";
import { apiGateway } from "../../utils/config";
import {
  errorState,
  GET_SERIES_LIST,
  GET_SERIES_V1,
  SERIES_SCHEDULE_V1,
  SERIES_V1_1,
} from "../../utils/constants";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  allSeriesDefaultState,
  handleSeriesStateTabs,
  openScheduledModalState,
} from "../../utils/constants/VideoListing";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Pagination,
  Tab,
  Tabs,
} from "@mui/material";
import {
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleClearAllFiltersFromURL,
  handleDeleteQueryFromUrl,
  handleRemoveParticularFilter,
  handleFormatDate,
  schedularTimeList,
} from "../../utils/utils";
import { TEAM_ROLES } from "../../utils/constants/Team";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SeriesListTable from "./SeriesListTable";
import CustomSearch from "../Common/CustomSearch";
import useZustandStore from "../../zustandStore/useZustandStore";
import AlertComponent from "../Alert-Messages/alert-component.component";
import CreateSeries from "../Create-Series/create-series.component";
import VideoPlayerCustom from "../VideoPlayer/VideoPlayerCustom";
import FiltersTags from "./FiltersTags";
import moment from "moment";
import FilterDrawer from "./FilterDrawer";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import ShowPills from "../Common/ShowPills";
import ShowPillsSkeleton from "../Skeletons/ShowPillsSkeleton";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VideoListing = ({
  isShowUploadCta = true,
  isOnShowDetailsPage,
  isOnCreatorDetailsPage,
}) => {
  const [playIndex, setPlayIndex] = useState(0);
  const [os, setOs] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTag, setSearchQueryTag] = useState("");
  const [videoSource, setVideoSource] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  const [showVideosLoading, setShowVideosLoading] = useState(false);
  const [selectScheduleActive, setSelectScheduleActive] = useState(false);
  const [errorForScheduling, setErrorForScheduling] = useState([]);
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedScheduleSeries, setSelectedScheduleSeries] = useState([]);
  const [scheduledData, setScheduledData] = useState({});
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [allSeries, setAllSeries] = useState(allSeriesDefaultState);
  const [isLoading, setIsLoading] = useState({ series: true, shows: true });
  const [openScheduleModal, setOpenScheduleModal] = useState(
    openScheduledModalState
  );
  const {
    openCreateSeriesModal,
    globalRoles,
    internalTeamAccessGlobalState,
    setOpenCreateSeriesModal,
  } = useZustandStore();
  const videoRef = useRef();
  const playerRef = useRef(null);
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state?.user?.currentUser);
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamFilters = searchParams?.getAll("filter") || [];
  const searchParamShowSlug = searchParams?.get("show") || "";
  const isCreator =
    currentUser?.roles?.includes("creator") || globalRoles?.creator;
  const hasAccessToCreate = internalTeamAccessGlobalState || isCreator;
  // because the initial value from zustand store was undefined so had to use currentUser
  const hasAccessToAllTabs =
    currentUser?.roles?.includes(TEAM_ROLES.admin) ||
    currentUser?.roles?.includes(TEAM_ROLES.superAdmin) ||
    currentUser?.roles?.includes(TEAM_ROLES.editor) ||
    currentUser?.roles?.includes(TEAM_ROLES.viewer);

  const handleGetSeries = async ({
    currentActiveTab,
    pageArg,
    query,
    passedId,
  }) => {
    try {
      setIsLoading({
        series: true,
        shows: allSeries?.shows?.[0] ? false : true,
      });
      let state = handleSeriesStateTabs({
        hasAccessToAllTabs,
      }).filter((d) => d.index === currentActiveTab)?.[0]?.value;

      // Filtering out the category with the passed id, used while removing the added filteredTags
      const filteredCategories = searchParamFilters.filter(
        (category) => category?.split("-")[1] !== passedId
      );

      // Prepare query parameters for categories
      const categoryParams = filteredCategories
        .map((item) => `category_ids=${Number(item?.split("-")?.[1])}`)
        .join("&");

      let url = `${apiGateway}${GET_SERIES_LIST}?page=${
        pageArg || pageNumber
      }&lang=en&q=${query}${state !== "all" ? `&status=${state}` : ""}${
        isOnShowDetailsPage?.showSlug
          ? `&show_slug=${isOnShowDetailsPage?.showSlug}`
          : ""
      }${
        isOnCreatorDetailsPage?.creatorId
          ? `&creator_ids=${isOnCreatorDetailsPage?.creatorId}`
          : ""
      }${searchParamShowSlug ? `&show_slug=${searchParamShowSlug}` : ""}`;

      // Add category if they exist
      if (categoryParams) {
        url += `&${categoryParams}`;
      }

      const response = await axiosPrivate.get(url);
      const data = response?.data;
      if (data) {
        setIsLoading({ series: false, shows: false });
        setAllSeries({
          nPages: data?.n_pages,
          series: data?.series,
          shows: data?.shows,
        });
      }
    } catch (error) {
      setAllSeries({ nPages: 0, series: [] });
      setIsLoading({ series: false, shows: false });
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
    }
  };

  // Function on tab change
  const handleChangeTabs = (event, newValue) => {
    let state = handleSeriesStateTabs({ hasAccessToAllTabs }).filter(
      (d) => d.index === newValue
    )?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  // function on Page change
  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  // function on hit enter
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
      setSearchQueryTag(searchQuery);
    }
  };

  // onChange function on selected filters
  const handleSelectedFilter = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevSelectedFilters) => {
      if (checked) {
        // Add the selected filter
        return [...prevSelectedFilters, value];
      } else {
        // Remove the unselected filter
        return prevSelectedFilters.filter((filter) => filter !== value);
      }
    });
  };

  // Apply filters function
  const handleApplyFilters = () => {
    handleAppendFiltersToUrl({ filters: selectedFilters, history });
    setOpenFilters(false);
  };

  //Remove Filters function
  const handleRemoveFilter = ({ filterToRemove }) => {
    handleRemoveParticularFilter({ filterToRemove, history });
  };

  // when clicked on particular show
  const handleOnShowClick = ({ showSlug }) => {
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
    handleAppendQueryToUrl({
      history,
      queryName: "show",
      queryValue: showSlug,
    });
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order"],
    });
  };

  // when clicked on All Show CTA Pill
  const handleClickOnAllShowCTA = () => {
    handleDeleteQueryFromUrl({
      history,
      queryNames: ["page", "s", "order", "show"],
    });
  };

  // function fetches fresh data after any action made
  const handleFetchSeriesAfterCreated = () => {
    handleGetSeries({
      currentActiveTab: searchParamTabState,
      pageArg: searchParamPage,
      query: searchQuery || searchParamSearchQuery,
    });
  };

  // videoPlay next
  const handlePlayNextVideo = async (currentPlayIndex, data) => {
    const index = currentPlayIndex >= 0 ? currentPlayIndex : playIndex + 1;
    if (index >= 0 && index < data?.length) {
      const video = data[index];
      const source = video?.content?.h264_media_url
        ? video?.content?.h264_media_url
        : video?.content?.url;
      setVideoSource((prev) => source);
      setVideoTitle((prev) => video?.title);
      setPlayIndex((prev) => index);
      setShowVideo(true);
    } else if (hasMoreVideos) {
      setPageNumber((prev) => pageNumber + 1);
    }
  };

  // previous video play function
  const handlePlayPreviousVideo = () => {
    const index = playIndex - 1;
    if (index >= 0 && index < seriesVideos?.length) {
      const video = seriesVideos[index];
      setVideoSource(video.content.h264_media_url);
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    }
  };

  // on video end
  const handleOnVideoEnd = (seriesVideos) => {
    handlePlayNextVideo(-1, seriesVideos);
  };

  // on Video Close Modal
  const handleVideoModal = (videoSource, videoTitle, playIndex) => {
    if (showVideo) {
      setShowVideo(false);
      setPlayIndex(0);
      setVideoSource(false);
      return;
    } else {
      setVideoSource(videoSource);
      setVideoTitle(videoTitle);
      setShowVideo((prev) => !prev);
      setPlayIndex(playIndex);
      playerRef.current = null;
    }
  };

  const fetchMoreVideo = async (page, slug) => {
    try {
      setShowVideo(true);
      setShowVideosLoading(true);
      const url = `${apiGateway}${SERIES_V1_1}${slug}/units/?page=1&lang=en&page_size=${page}`;
      const { data, status } = await axiosPrivate.get(url);
      if (data && status === 200) {
        setHasMoreVideos(data?.has_more);
        if (data?.content_units) {
          setSeriesVideos([...data.content_units]);
          const video = data.content_units[0];
          setPlayIndex(0);
          handlePlayNextVideo(0, data.content_units);
        }
      }
    } catch (err) {
      setShowVideosLoading(false);
    } finally {
      setShowVideosLoading(false);
    }
  };

  const playAllVideos = (n_unit, s_slug) => {
    fetchMoreVideo(n_unit, s_slug);
  };

  const getDateTimeFormat = (date) => {
    const newDate = moment(date).format("DD MM YYYY");
    return newDate?.split(" ").join(" - ");
  };

  // Old function code to update the scheduled date
  const handleUpdateScheduleDat = async (data, type, edit) => {
    let url = `${apiGateway}${SERIES_SCHEDULE_V1}`;
    let dynamicFormDate;
    if (type === "custom") {
      dynamicFormDate = new Date(data).toLocaleString();
    } else {
      dynamicFormDate = new Date(
        `${data?.value?.split("/")[0]}-${data?.value?.split("/")[1]}-${
          data?.value?.split("/")[2]
        }`
      );
    }
    const dynamicDate =
      type === "custom"
        ? `${dynamicFormDate?.split(",")[0]?.split("/")[2]}-${
            dynamicFormDate?.split(",")[0]?.split("/")[1]
          }-${dynamicFormDate?.split(",")[0]?.split("/")[0]}T${
            dynamicFormDate?.split(", ")[1]
          }+05:30`
        : `${dynamicFormDate.getFullYear()}-${
            dynamicFormDate.getMonth() + 1
          }-${dynamicFormDate.getDate()}T18:00:00+05:30`;

    let formData = new FormData();
    if (edit?.length > 0) {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slug", openScheduleModal?.slug);
    } else {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slugs", JSON.stringify(selectedScheduleSeries));
    }
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status === 200) {
          handleGetSeries({
            currentActiveTab: searchParamTabState,
            query: searchParamSearchQuery,
            page: 1,
          });
          setErrorMsg({ error: "Scheduled successfully", type: "success" });
          setOpenScheduleModal(openScheduledModalState);
          setSelectScheduleActive((prev) => !prev);
        } else {
          setErrorForScheduling(data?.failure_slugs);
        }
        if (data?.message?.length > 0) {
          setErrorMsg({ error: data?.message, type: "failed" });
          setOpenScheduleModal(openScheduledModalState);
          setSelectScheduleActive((prev) => !prev);
        }
        setPageNumber(1);
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.message
            : "Unable to create scheduling",
          type: "failed",
        });
        setOpenScheduleModal(openScheduledModalState);
        setSelectScheduleActive((prev) => !prev);
      });
  };

  // Function to remove the scheduled date
  const handleRemoveScheduled = async ({ seriesSlug }) => {
    let url = `${apiGateway}${GET_SERIES_V1}${seriesSlug}/schedule/delete/`;
    try {
      const response = await axiosPrivate.delete(url);
      const { data } = response;
      if (data) {
        setErrorMsg({ error: data?.message, type: "success" });
        handleGetSeries({
          currentActiveTab: searchParamTabState,
          query: searchParamSearchQuery,
          page: 1,
        });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Unable to remove scheduling";
      setErrorMsg({
        error: errorMessage,
        type: "failed",
      });
    }
  };

  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // useEffect handling get API on every dependencies change
  useEffect(() => {
    handleGetSeries({
      currentActiveTab: searchParamTabState,
      pageArg: searchParamPage,
      query: searchQuery || searchParamSearchQuery,
    });
  }, [
    searchParamTabState,
    searchParamPage,
    searchParamSearchQuery,
    searchParamFilters?.length,
    isOnShowDetailsPage?.showSlug,
    searchParamShowSlug,
  ]);

  return (
    <div className="relative mt-3">
      <div>
        <div className="flex gap-x-3 !w-full flex-col lg:flex-row">
          <div className="flex w-full !items-center gap-x-4">
            {/* Custom Search & Filter CTA */}
            <div className="w-[70%] lg:!w-[55%]">
              <CustomSearch
                wrapperClass="flex items-center !bg-white rounded-full justify-between border px-2"
                className="w-[95%] focus:outline-none rounded-r-full placeholder:text-[12px] text-[14px] py-[10px] px-2"
                placeHolder="Search video title or video id"
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                handleInputKey={handleInputKey}
                handleClearInput={() => {
                  setSearchQuery("");
                  setSearchQueryTag("");
                  handleDeleteQueryFromUrl({
                    history,
                    queryNames: ["query", "page"],
                  });
                }}
                handleClickedOnSearchIcon={() => {
                  setSearchQueryTag(searchQuery);
                  handleGetSeries({
                    currentActiveTab: searchParamTabState,
                    query: searchParamSearchQuery,
                    page: 1,
                  });
                }}
              />
            </div>
            <Button
              size="small"
              variant="contained"
              className="text-[12px] py-[10px] border"
              onClick={() => {
                setOpenFilters(true);
                setSelectedFilters(searchParamFilters);
              }}
            >
              <FontAwesomeIcon icon={faFilter} /> FILTERS
            </Button>
          </div>

          {isShowUploadCta && (
            <div className="flex items-center mt-3 lg:!mt-0">
              {hasAccessToCreate && (
                <Button
                  size="small"
                  variant="contained"
                  className="text-[13px] !bg-[#534f8f] !text-white h-fit w-[140px] py-[10px]"
                  onClick={() => setOpenCreateSeriesModal(true)}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" /> Upload
                  Video
                </Button>
              )}
            </div>
          )}
        </div>

        <h6 className="!text-[20px] mt-2 font-medium">Videos</h6>

        {/* Filter tags */}
        <FiltersTags
          history={history}
          searchQueryTag={searchQueryTag}
          searchParamFilters={searchParamFilters}
          searchParamSearchQuery={searchParamSearchQuery}
          setSearchQuery={setSearchQuery}
          setSearchQueryTag={setSearchQueryTag}
          handleRemoveFilter={handleRemoveFilter}
          handleRemoveSearchQueryFromUrl={handleDeleteQueryFromUrl}
        />

        {isOnShowDetailsPage?.value || isOnCreatorDetailsPage?.value ? null : (
          <div className="mt-2">
            {isLoading?.shows ? (
              <ShowPillsSkeleton />
            ) : (
              <div>
                {allSeries?.shows && (
                  <ShowPills
                    shows={allSeries?.shows}
                    searchParamShowSlug={searchParamShowSlug}
                    handleOnShowClick={handleOnShowClick}
                    handleClickOnAllShowCTA={handleClickOnAllShowCTA}
                  />
                )}
              </div>
            )}
          </div>
        )}

        {/* State Tabs */}
        <div className="mt-3" style={{ overflowX: "auto" }}>
          <Box>
            <Tabs
              value={searchParamTabState}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto" // Enables scrolling when tabs overflow
              allowScrollButtonsMobile // Enables scroll buttons for mobile view
            >
              {handleSeriesStateTabs({
                hasAccessToAllTabs,
              })?.map((data, i) => (
                <Tab
                  label={data?.id}
                  {...a11yProps(i)}
                  key={i}
                  className="!text-[13px]"
                />
              ))}
            </Tabs>
          </Box>
        </div>
      </div>

      {/* Listing table with pagination and UI handle when no series */}
      <div>
        {isLoading.series && <LinearProgress color="inherit" />}
        <div
          id="ThinGrayScrollBar"
          className="!relative !overflow-x-auto h-[calc(100vh-255px)] rounded-md !bg-white pb-2"
        >
          {!isLoading.series && allSeries?.series?.length === 0 ? (
            <div className="h-full flex justify-center items-center">
              <NoFeedbackSvj
                displayText="No videos to display"
                displayTextClass="text-center !text-gray-500"
                className="w-[400px] mx-auto flex justify-center items-center"
              />
            </div>
          ) : (
            <div>
              <SeriesListTable
                allSeries={allSeries?.series}
                playAllVideos={playAllVideos}
                setOpenScheduleModal={setOpenScheduleModal}
                setScheduledData={setScheduledData}
                handleRemoveScheduled={handleRemoveScheduled}
                handleGetSeries={() =>
                  handleGetSeries({
                    currentActiveTab: searchParamTabState,
                    query: searchParamSearchQuery,
                    pageArg: searchParamPage,
                  })
                }
              />
              {allSeries?.nPages > 1 && (
                <Pagination
                  count={allSeries?.nPages}
                  page={searchParamPage || pageNumber}
                  onChange={(e, val) => handlePageChange(e, val)}
                  shape="rounded"
                  className="w-fit mx-auto"
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Filter Side Drawer */}
      <FilterDrawer
        openFilters={openFilters}
        selectedFilters={selectedFilters}
        setOpenFilters={setOpenFilters}
        handleSelectedFilter={handleSelectedFilter}
        handleApplyFilters={handleApplyFilters}
        handleClearAllFiltersFromURL={handleClearAllFiltersFromURL}
      />

      {/* CREATE Video MODAL */}
      <Modal show={openCreateSeriesModal} centered>
        <Modal.Header
          closeButton
          onHide={() => setOpenCreateSeriesModal(false)}
        >
          <div className="modal-video-title">Upload Video</div>
        </Modal.Header>
        <Modal.Body>
          <CreateSeries
            toggleCreate={() => setOpenCreateSeriesModal(false)}
            handleFetchSeriesAfterCreated={handleFetchSeriesAfterCreated}
          />
        </Modal.Body>
      </Modal>

      {/* Old Code Of Video Modal */}
      <Modal
        style={{ width: "120vw" }}
        className="video-modal"
        show={showVideo}
        centered
      >
        <Modal.Header closeButton onHide={handleVideoModal}>
          <div className="modal-video-title">
            {videoTitle?.length > 60
              ? videoTitle?.substr(0, 60) + "..."
              : videoTitle}
          </div>
        </Modal.Header>
        <div className="modal-video-player" style={{ height: "100%" }}>
          <div className="icon">
            {playIndex > 0 && (
              <div className="prev-icon" onClick={handlePlayPreviousVideo}>
                <FontAwesomeIcon
                  size="2x"
                  className="icon"
                  icon={faChevronCircleLeft}
                />
              </div>
            )}
            {(playIndex < seriesVideos.length - 1 || hasMoreVideos) && (
              <div
                className="next-icon"
                onClick={() => handlePlayNextVideo(-1, seriesVideos)}
              >
                <FontAwesomeIcon
                  size="2x"
                  className="icon"
                  icon={faChevronCircleRight}
                />
              </div>
            )}
          </div>
          {showVideosLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : videoSource ? (
            <VideoPlayerCustom
              showNextPrev={true}
              controlProps={{
                playIndex: playIndex,
                seriesVideos: seriesVideos,
                hasMoreVideos: hasMoreVideos,
                handlePlayPreviousVideo: handlePlayPreviousVideo,
                handlePlayNextVideo: (seriesVideos) =>
                  handlePlayNextVideo(-1, seriesVideos),
              }}
              src={videoSource}
              controls={true}
              muted={false}
              autoplay={true}
              onEnded={handleOnVideoEnd}
              onTimeUpdate={() => {}}
              videoRef={videoRef}
              currentTime={0}
            />
          ) : (
            <div className="message">Source Not Found</div>
          )}
        </div>
      </Modal>

      {/* Old code of Update scheduled date Modal */}
      <Modal show={openScheduleModal?.openModal} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setOpenScheduleModal(openScheduledModalState);
            setErrorForScheduling([]);
            setScheduledData({});
          }}
        >
          <div className="modal-schedule-title">
            <div className="modal-schedule-header">Schedule Publish</div>
            <div className="modal-schedule-subtext">India Standard Time</div>
          </div>
        </Modal.Header>
        {errorForScheduling?.length > 0 ? (
          errorForScheduling?.map((data, i) => (
            <div key={i}>
              <ul className="modal-schedule-content-ul">
                <li style={{ color: "red" }}>
                  {i + 1} .{data?.series_slug}: {data?.error_message}
                </li>
              </ul>
            </div>
          ))
        ) : (
          <div className="modal-schedule-content">
            <ul className="modal-schedule-content-ul">
              {schedularTimeList(os)?.map((dateinfo, i) => {
                return (
                  <li
                    key={i}
                    className="modal-schedule-content-li"
                    onClick={() =>
                      handleUpdateScheduleDat(
                        dateinfo,
                        "",
                        scheduledData?.schedule_on
                      )
                    }
                  >
                    <div className="modal-date-title">{dateinfo?.title}</div>
                    <div>
                      <span className="modal-date-date">
                        {getDateTimeFormat(dateinfo?.value)}
                      </span>
                      <span className="modal-date-time">{dateinfo?.time}</span>
                    </div>
                  </li>
                );
              })}
              {scheduledData?.schedule_on && (
                <li className={`modal-schedule-content-li selected-date`}>
                  <div className="modal-date-title">Scheduled Date</div>
                  <div>
                    <span className="modal-date-date">
                      {handleFormatDate({
                        dateInString: scheduledData?.schedule_on,
                      })}
                    </span>
                  </div>
                </li>
              )}
            </ul>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  label="Select time and Date"
                  disablePast
                  ampm={false}
                  onAccept={(newValue) =>
                    newValue &&
                    handleUpdateScheduleDat(
                      newValue?.$d,
                      "custom",
                      scheduledData?.schedule_on
                    )
                  }
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </Modal>

      {/* Alert Component */}
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() =>
          setErrorMsg({ error: "", type: errorMsg?.type })
        }
      />
    </div>
  );
};

export default VideoListing;

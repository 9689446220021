import React, { useState, useEffect } from "react";
import "./creator.styles.css";
import { Table, Modal, Form, Dropdown } from "react-bootstrap";
import NoImg from "../../assests/images/nodatafound.png";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import {
  selectAllCreators,
  selectIsCreatorsFetching,
  selectCurrentPageNumber,
  selectHasMore,
  selectFilters as selectedCreatorFilters,
  selectLastFetchedPageNumber,
} from "../../redux/creator/creator.selector";

import { selectFilters as selectVideoFilters } from "../../redux/video/video.selector";
import {
  setFilters as setVideoFilters,
  setHasMore as setHasMoreVideos,
} from "../../redux/video/video.action";

import {
  setFilters as setCreatorFilters,
  setHasMore as setHasMoreCreator,
} from "../../redux/creator/creator.action";
import {
  changePromotionScoreStartAsync,
  changeQualityApprovedStartAsync,
  fetchCreatorsStartAsync,
} from "../../redux/creator/creator.action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faEllipsisV,
  faFilter,
  faPlusSquare,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { getDateString, handleDefaultProfile } from "../../utils/utils";
import UpdateContentManager from "../Update-Content-Manager/update-content-manager";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  Paper,
  Button,
  Drawer,
  Box,
  Card,
  LinearProgress,
  Chip,
  Pagination,
} from "@mui/material";
import { apiGateway } from "../../utils/config";
import { CREATE_CREATOR } from "../../utils/constants";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CustomModal from "../Common/CustomModal";
import AddCreator from "./AddCreator";
import AddIcon from "@mui/icons-material/Add";
import useZustandStore from "../../zustandStore/useZustandStore";

function Creator({
  creators,
  isCreatorFetching,
  fetchCreators,
  setVideoFilters,
  setCreatorFilters,
  setHasMoreVideos,
  setHasMoreCreator,
  selectedVideoFilters,
  selectedCreatorFilters,
  currentPageNumber,
  hasMoreCreators,
  setVideoCurrentPageNumber,
  changeQualityApproved,
  changePromotionScore,
  lastPageFetched,
  configFilters,
}) {
  const history = useHistory();
  const axiosPrivate = useAxiosPrivate();
  const [showAction, setShowAction] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [pageNumber, setPageNumber] = useState(currentPageNumber);
  const [errorMsg, setErrorMsg] = useState({
    error: "",
    type: "",
  });
  const [showSearch, setShowSearch] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchedName, setSearchedName] = useState("");
  const [showPromotionModal, setShowPromotionModal] = useState(false);
  const [showContentManager, setShowContentManager] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [openAddCreatorModal, setOpenAddCreatorModal] = useState(false);
  const [addCreatorIsLoading, setAddCreatorIsLoading] = useState(false);
  const [creatorDetails, setCreatorDetails] = useState({
    name: "",
    mobileNumber: "",
  });
  const [contentManagerFilter, setContentManagerFilter] = useState([]);
  const sortKey = useSelector((state) => state.creator.sortKey);
  const sortReverse = useSelector((state) => state.creator.sortReverse);
  const creatorPageData = useSelector((state) => state.creator.pageData);
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val.internalTeamAccessGlobalState
  );
  const hasAccessToCreate = internalTeamAccessGlobalState;
  const hasAccessToActions = internalTeamAccessGlobalState;
  const promotionScores = [-1, 0, 1];
  let promotionScore = null;

  useEffect(() => {
    const filters = selectedCreatorFilters;
    if (pageNumber === -1) {
      setPageNumber(currentPageNumber + 1);
    } else if (pageNumber === 0) {
      setHasMoreCreator(true);
      setPageNumber(1);
    } else if (pageNumber !== 0) {
      fetchCreators(
        axiosPrivate,
        pageNumber,
        searchedName,
        filters,
        sortKey,
        sortReverse,
        setErrorMsg
      );
    }
  }, [pageNumber, searchedName]);

  const hideAction = () => {
    if (showAction && selectedCreator !== null) {
      setShowAction(false);
    }
  };

  const showCreatorVideos = (creator) => {
    const filters = selectedVideoFilters;
    filters.set("categoryFilter", []);
    filters.set("statusFilter", []);
    filters.set("durationFilter", []);
    filters.set("creatorFilter", [creator]);
    setHasMoreVideos(true);
    // history.push({ pathname: "/videos", state: { creator } });
  };

  const showContentManagerModal = (creator) => {
    setSelectedCreator(creator);
    setShowContentManager(true);
  };

  const applyFilter = () => {
    setPageNumber((prev) => {
      return 0;
    });
    setShowFilter(false);
  };

  const handleChange = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearch = (e) => {
    if (searchName.length < 3) {
      setErrorMsg({
        type: "failed",
        error: "Enter minimum 3 characters for search",
      });
      return;
    }
    setPageNumber(1);
    setShowSearch(true);
    setSearchedName(searchName);
  };

  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleSearch();
    }
  };

  const removeSearch = () => {
    setSearchName("");
    setSearchedName("");
    setShowSearch(false);
    setPageNumber(1);
  };

  const handleSelectFilter = (e) => {
    const filters = selectedCreatorFilters;
    const filterType = e.target.name;
    const isChecked = e.target.checked;

    if (filterType === "categoryFilter") {
      const categoryId = e.target.value;
      let categoryFilter = filters.get("categoryFilter");
      if (isChecked) {
        categoryFilter = categoryFilter.concat(categoryId);
      } else {
        categoryFilter = categoryFilter.filter((id) => categoryId !== id);
      }
      filters.set("categoryFilter", categoryFilter);
    } else if (filterType === "contentManagerFilter") {
      const contentManager = e.target.value;
      let contentManagerFilter = filters.get("contentManagerFilter");
      if (isChecked) {
        contentManagerFilter = contentManagerFilter.concat(contentManager);
      } else {
        contentManagerFilter = contentManagerFilter.filter(
          (value) => contentManager !== value
        );
      }
      setContentManagerFilter(contentManagerFilter);
      filters.set("contentManagerFilter", contentManagerFilter);
    }
    setCreatorFilters(filters);
  };

  const copyEmail = async (email) => {
    await navigator?.clipboard?.writeText(email);
  };

  const handleSort = (sortingKey) => {
    setHasMoreCreator(true);
    if (sortingKey === sortKey) {
      fetchCreators(
        axiosPrivate,
        1,
        searchedName,
        selectedCreatorFilters,
        sortingKey,
        !sortReverse,
        setErrorMsg
      );
    } else {
      fetchCreators(
        axiosPrivate,
        1,
        searchedName,
        selectedCreatorFilters,
        sortingKey,
        false,
        setErrorMsg
      );
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilter(!showFilter);
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const handleMissingFields = () => {
    const missingFields = !creatorDetails?.name
      ? "Name is required"
      : !creatorDetails?.mobileNumber
      ? "Mobile Number is required"
      : creatorDetails?.mobileNumber?.length !== 10
      ? "Mobile Number is not valid"
      : null;
    return missingFields;
  };

  const handleAddCreatorApiCall = async () => {
    try {
      setAddCreatorIsLoading(true);
      const filters = selectedCreatorFilters;
      const url = `${apiGateway}${CREATE_CREATOR}`;
      const payload = {
        name: creatorDetails?.name,
        phone_number: creatorDetails?.mobileNumber,
      };
      const response = await axiosPrivate.post(url, payload);
      if (response?.data) {
        setAddCreatorIsLoading(false);
        setOpenAddCreatorModal(false);
        setErrorMsg({
          error: `Invite link sent to ${creatorDetails?.name} via WhatsApp`,
          type: "success",
        });
        setCreatorDetails({ name: "", mobileNumber: "" });
        fetchCreators(
          axiosPrivate,
          pageNumber,
          searchedName,
          filters,
          sortKey,
          sortReverse,
          setErrorMsg
        );
      }
    } catch (error) {
      setAddCreatorIsLoading(false);
      setErrorMsg({
        error: error?.response?.data?.error_message || error?.message,
        type: "error",
      });
    }
  };

  const handleAddCreator = () => {
    const isValid =
      !creatorDetails?.name ||
      !creatorDetails?.mobileNumber ||
      creatorDetails?.mobileNumber?.length !== 10;
    if (isValid) {
      setErrorMsg({ error: handleMissingFields(), type: "error" });
    } else {
      handleAddCreatorApiCall();
    }
  };

  const handleCloseCreatorModal = () => {
    setCreatorDetails({ name: "", mobileNumber: "", image: null });
    setOpenAddCreatorModal(false);
  };

  return (
    <div className="creators-page">
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />

      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={() => setOpenAddCreatorModal(true)}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Add Creator</span>
        </div>
      )}

      <div onClick={hideAction}>
        <div className="page-header-series">
          <Paper elevation={0} className="search-paper mb-2">
            {/* Search box */}
            <div className="mt-2">
              <div className="container-search-box">
                <div className="creator-search serieslist-search-col custom-searchbox">
                  {/* <div className={`search-box w-100 custom-searchbox`}> */}
                  <input
                    label="Search"
                    type="search"
                    onChange={handleChange}
                    minLength="8"
                    onKeyPress={handleInputKey}
                    value={searchName}
                    placeholder="Enter Creator Name..."
                    className="search-box-input-serieslist bg-white  w-100"
                  />
                  <FontAwesomeIcon
                    className="search-icon bg-white pb-1"
                    icon={faSearch}
                    color="black"
                    size="2x"
                    onClick={handleSearch}
                  />
                </div>
                <div className="action-button-group">
                  <Button
                    size="small"
                    variant="contained"
                    style={{ paddingBottom: 2 }}
                    className="filters-button float-right custom-filter-button"
                    onClick={() => setShowFilter((prev) => !prev)}
                  >
                    <FontAwesomeIcon icon={faFilter} /> <span>FILTERS</span>
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <Drawer anchor="right" open={showFilter} onClose={toggleDrawer(false)}>
          <Box sx={{ width: 350, overflow: "hidden" }} role="presentation">
            <div className="filters-series box-filter-drawer">
              <div className="categories-list-series">
                <h5 className="filter-header">Status</h5>
                <Form.Group
                  className="form-checkbox-align filters-list-series custom-drawer-list-series"
                  // as={Row}
                >
                  <Form.Check
                    key={1}
                    type="checkbox"
                    className="filter-box col-md-6 my-0 form-check-label"
                    name="contentManagerFilter"
                    onChange={handleSelectFilter}
                    value={1}
                    label={"Active"}
                    // id={contentManager.name}
                    // checked={
                    //   selectedCreatorFilters
                    //     .get("contentManagerFilter")
                    //     .indexOf(contentManager.id.toString()) > -1
                    //     ? true
                    //     : false
                    // }
                  ></Form.Check>
                  <br />
                  <Form.Check
                    // key={contentManager.id + `${index}`}
                    type="checkbox"
                    name="contentManagerFilter"
                    onChange={handleSelectFilter}
                    value={0}
                    className="filter-box col-md-6 my-1 form-check-label"
                    label={"In Active"}
                    // id={contentManager.name}
                    // checked={
                    //   selectedCreatorFilters
                    //     .get("contentManagerFilter")
                    //     .indexOf(contentManager.id.toString()) > -1
                    //     ? true
                    //     : false
                    // }
                  ></Form.Check>
                </Form.Group>
              </div>
            </div>
            <div className="action-button-filter">
              {/* APPLY FILTER BUTTON */}
              {showFilter && (
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  className="apply-button"
                >
                  APPLY
                </Button>
              )}
            </div>
          </Box>
        </Drawer>
        <div className="full-layout-scroll-enable">
          {showSearch && (
            <div className="chip-name">
              <Chip
                label={searchedName}
                className="m-2 p-2 chip-hardcode"
                variant="filled"
                color="success"
                onDelete={(e) => {
                  removeSearch(e);
                }}
              />
            </div>
          )}
          <div className="mt-1">
            <div className="card-panel creator-table">
              <Card
                className="shadow-table"
                style={{ border: "1px solid var(--search_chip)" }}
              >
                {hasMoreCreators && isCreatorFetching ? (
                  <LinearProgress color="inherit" />
                ) : (
                  <div style={{ height: 4, background: "white" }}></div>
                )}
                <Table hover responsive className="table-series">
                  <thead style={{ position: "sticky", zIndex: 1000 }}>
                    <tr>
                      <th>Creator</th>
                      <th>Phone</th>
                      <th>Score</th>
                      <th>Content Manager</th>
                      <th>Joined</th>
                      <th>Email</th>
                      <th>
                        Series{" "}
                        {!searchedName?.length > 0 && (
                          <FontAwesomeIcon
                            className="sort-icon"
                            icon={
                              sortKey === "n_series" && sortReverse
                                ? faArrowDown
                                : faArrowUp
                            }
                            onClick={() => handleSort("n_series")}
                          />
                        )}
                      </th>
                      <th>
                        Views{" "}
                        {!searchedName?.length > 0 && (
                          <FontAwesomeIcon
                            className="sort-icon"
                            icon={
                              sortKey === "n_total_views" && sortReverse
                                ? faArrowDown
                                : faArrowUp
                            }
                            onClick={() => handleSort("n_total_views")}
                          />
                        )}
                      </th>
                      {hasAccessToActions && (
                        <th style={{ width: "120px" }}>Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {creators?.length > 0 &&
                      creators?.map((creator, index) => {
                        const contentManager = creator.content_manager.name
                          ? creator.content_manager.name
                          : "None";
                        return (
                          <tr
                            key={`${creator.id}-${index}`}
                            className="creator-row"
                          >
                            <td
                              className="large user-abvatar-name"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              <div className="creator-image user-abvatar-img">
                                <img
                                  src={
                                    creator.avatar ??
                                    handleDefaultProfile({
                                      userName: creator?.name,
                                    })
                                  }
                                  className="creator-avatar-table"
                                  alt="AVATAR"
                                />
                                <div className="blue-tick-icon-creator">
                                  {creator.is_quality_approved && (
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      color="#38529A"
                                      size="1x"
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="creator-name mt-2">
                                {creator.name}
                              </div>
                            </td>
                            <td
                              className="large"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              {creator.phone}
                            </td>
                            <td
                              className="small"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              {creator.promotion_score
                                ? creator.promotion_score
                                : "0"}
                            </td>
                            <td
                              className="small"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              <span
                                className="content-manager"
                                onClick={() => {
                                  showContentManagerModal(creator);
                                }}
                              >
                                {contentManager}
                              </span>
                            </td>
                            <td
                              className="mid"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              {getDateString(creator.signedup_on)}
                            </td>
                            <td
                              className="email"
                              onClick={() => {
                                window.location.href = `#/creators/${creator.id}`;
                                copyEmail(creator.email);
                              }}
                            >
                              {creator.email}
                            </td>
                            <td
                              className="small text-align-center"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              {creator.n_series}
                            </td>
                            <td
                              className="small text-align-center"
                              onClick={() =>
                                (window.location.href = `#/creators/${creator.id}`)
                              }
                            >
                              {creator.n_views}
                            </td>
                            {hasAccessToActions && (
                              <td>
                                <Dropdown className="w-100">
                                  <Dropdown.Toggle className="dropdown-action">
                                    <FontAwesomeIcon
                                      className="rejection-logs ml-1"
                                      icon={faEllipsisV}
                                      size="1x"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="action-menu">
                                    <Dropdown.Item
                                      className="action-menu-item"
                                      onClick={() => showCreatorVideos(creator)}
                                    >
                                      All videos
                                    </Dropdown.Item>
                                    {creator.is_quality_approved ? (
                                      <Dropdown.Item
                                        className="action-menu-item"
                                        onClick={() =>
                                          changeQualityApproved(
                                            axiosPrivate,
                                            creator.id,
                                            false,
                                            setErrorMsg
                                          )
                                        }
                                      >
                                        Disapprove
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        className="action-menu-item"
                                        onClick={() =>
                                          changeQualityApproved(
                                            axiosPrivate,
                                            creator.id,
                                            true,
                                            setErrorMsg
                                          )
                                        }
                                      >
                                        Approve
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!isCreatorFetching && creators?.length == 0 && (
                  <div className="img-nofound-wrapper">
                    <img
                      src={NoImg}
                      className="img-nofound"
                      alt="No data img"
                    />
                  </div>
                )}
              </Card>
              <div className="pagination-table-bottom">
                <Pagination
                  count={creatorPageData?.n_pages}
                  page={currentPageNumber}
                  onChange={(e, val) => handlePageChange(e, val)}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
            <Modal
              show={showPromotionModal}
              centered
              className="promotion-modal"
            >
              <Modal.Body>
                <div>
                  Change Promotion Score
                  <select
                    className="scores"
                    onChange={(e) => {
                      promotionScore = e.target.value;
                    }}
                  >
                    {selectedCreator &&
                      promotionScores?.map((score, i) => {
                        return (
                          <option
                            key={i}
                            selected={score === selectedCreator.promotion_score}
                          >
                            {score}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="promotion-modal-btn">
                  <Button
                    className="close-btn"
                    onClick={() => setShowPromotionModal(false)}
                  >
                    Close
                  </Button>
                  <Button
                    className="change-btn"
                    onClick={() => {
                      changePromotionScore(
                        selectedCreator.id,
                        promotionScore,
                        setErrorMsg
                      );
                    }}
                  >
                    Change
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
            <Modal show={showContentManager} centered>
              <UpdateContentManager
                creator={selectedCreator}
                setErrorMsg={setErrorMsg}
                close={() => {
                  setShowContentManager(false);
                }}
              />
            </Modal>

            <CustomModal
              show={openAddCreatorModal}
              onHide={handleCloseCreatorModal}
              title="Add Creator"
              isLoading={addCreatorIsLoading}
              handleConfirmationBtnText="Add Creator"
              handleConfirmation={handleAddCreator}
            >
              <AddCreator
                creatorDetails={creatorDetails}
                setCreatorDetails={setCreatorDetails}
              />
            </CustomModal>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  creators: selectAllCreators,
  isCreatorFetching: selectIsCreatorsFetching,
  currentPageNumber: selectCurrentPageNumber,
  selectedVideoFilters: selectVideoFilters,
  selectedCreatorFilters: selectedCreatorFilters,
  hasMoreCreators: selectHasMore,
  lastPageFetched: selectLastFetchedPageNumber,
  configFilters: selectConfigFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCreators: (
    axiosPrivate,
    pageNumber,
    searchName,
    filters,
    sortKey,
    sortReverse,
    setErrorMsg
  ) =>
    dispatch(
      fetchCreatorsStartAsync(
        axiosPrivate,
        pageNumber,
        searchName,
        filters,
        sortKey,
        sortReverse,
        setErrorMsg
      )
    ),
  setVideoFilters: (filters) => dispatch(setVideoFilters(filters)),
  setCreatorFilters: (filters) => dispatch(setCreatorFilters(filters)),
  setHasMoreVideos: (hasMore) => dispatch(setHasMoreVideos(hasMore)),
  setHasMoreCreator: (hasMore) => dispatch(setHasMoreCreator(hasMore)),
  changeQualityApproved: (
    axiosPrivate,
    creatorId,
    isQualityApproved,
    setErrorMsg
  ) =>
    dispatch(
      changeQualityApprovedStartAsync(
        axiosPrivate,
        creatorId,
        isQualityApproved,
        setErrorMsg
      )
    ),
  changePromotionScore: (axiosPrivate, creatorId, score, setErrorMsg) =>
    dispatch(
      changePromotionScoreStartAsync(
        axiosPrivate,
        creatorId,
        score,
        setErrorMsg
      )
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Creator);

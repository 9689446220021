import React, { useState, useEffect, useRef } from "react";
import "./series-list.styles.css";
import { Table, Modal, Row, Form, Dropdown } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import NoImg from "../../assests/images/nodatafound.png";
import { useHistory, Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import Card from "@mui/material/Card";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AlertComponent from "../Alert-Messages/alert-component.component";
import {
  Paper,
  Chip,
  Button,
  TextareaAutosize,
  Tabs,
  Box,
  Tab,
  LinearProgress,
  Drawer,
  Checkbox,
  Pagination,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  selectAllSeries,
  selectIsSeriesFetching,
  selectCurrentPageNumber,
  selectHasMore,
  selectFilters as selectedCreatorFilters,
} from "../../redux/series/series.selector";
import { selectFilters as selectSeriesFilters } from "../../redux/series/series.selector";
import {
  setFilters as setSeriesFilters,
  setHasMore as setHasMoreSeries,
  changeSeriesStatusAsync,
  initializeSeriesData,
  fetchRejectionReason,
  updateSelectedTab,
  setSelectedCreatorSeriesFilter,
} from "../../redux/series/series.action";

import Loader from "../../components/Loader/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faFilter,
  faSearch,
  faEllipsisV,
  faPlayCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faArrowDown,
  faArrowUp,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  filterKeywordToBackendKeywords,
  handleFormatDate,
  getDuration,
  schedularTimeList,
} from "../../utils/utils";
import { fetchSeriesStartAsync } from "../../redux/series/series.action";
import Divider from "@mui/material/Divider";
import placeholder from "../../assests/images/placeholder1.png";
import CreateSeries from "../Create-Series/create-series.component";
import { selectConfigFilters } from "../../redux/config/config.selector";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { apiGateway } from "../../utils/config";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import moment from "moment";
import VideoPlayerCustom from "../VideoPlayer/VideoPlayerCustom";
import {
  GET_SERIES_V1,
  SERIES_SCHEDULE_V1,
  SERIES_V1_1,
} from "../../utils/constants";
import { QueryStatsOutlined } from "@mui/icons-material";
import useZustandStore from "../../zustandStore/useZustandStore";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const promotionScores = [];
for (let i = 0; i <= 1; i++) {
  promotionScores.push(i);
}

const quickRowFilters = [
  {
    id: "ALL",
    key: "SERIES",
    index: 0,
    show: true,
  },
  {
    id: "DRAFT",
    key: "DRAFT",
    index: 1,
    show: true,
  },
  {
    id: "UNDER REVIEW",
    key: "UNDER_REVIEW",
    index: 2,
    show: true,
  },
  {
    id: "LIVE",
    key: "LIVE",
    index: 3,
    show: true,
  },
  {
    id: "CHANGES REQUIRED",
    key: "CHANGES_REQUIRED",
    index: 4,
    show: true,
  },
  {
    id: "SCHEDULED SERIES",
    key: "SCHEDULED",
    index: 5,
    show: true,
  },
  {
    id: "DELETED SERIES",
    key: "DELETED",
    index: 6,
    show: true,
  },
  {
    id: "EXPIRED SERIES",
    key: "EXPIRED",
    index: 7,
    show: true,
  },
];

// JS ENUM OBJECT
const quickFilters = Object.freeze({
  PROMOTED_SERIES: "PROMOTED_SERIES",
  PREMIUM_SERIES: "PREMIUM_SERIES",
  SERIES: "SERIES",
  SCHEDULED: "SCHEDULED",
});

const quickFiltersSeries = Object.freeze({
  DRAFT: "DRAFT",
  SERIES: "SERIES",
  UNDER_REVIEW: "UNDER_REVIEW",
  LIVE: "LIVE",
  CHANGES_REQUIRED: "CHANGES_REQUIRED",
  PROMOTED_SERIES: "PROMOTED_SERIES",
  SCHEDULED: "SCHEDULED",
  DELETED: "DELETED",
  EXPIRED: "EXPIRED",
});

const quickFiltersSeriesValueMapping = Object.freeze({
  DRAFT: "draft",
  UNDER_REVIEW: "under_review",
  LIVE: "live",
  CHANGES_REQUIRED: "changes_required",
  SCHEDULED: "scheduled",
  DELETED: "deleted",
  EXPIRED: "expired",
});

function SeriesList({
  series,
  hasMoreSeries,
  isCreatorFetching,
  setSeriesFilters,
  setCreatorFilters,
  setHasMoreSeries,
  setHasMoreCreator,
  selectedSeriesFilters,
  selectedCreatorFilters,
  currentPageNumber,
  hasMoreCreators,
  configFilters,
  creator,
  initializeSeries,
  ...remainingProps // remainingProps spread operator good to use for re useability PASSING PROPS SMARTLY
}) {
  const axiosPrivate = useAxiosPrivate();
  const history = useHistory();
  const dispatch = useDispatch();
  const videoRef = useRef();
  const playerRef = useRef(null);
  const [showAction, setShowAction] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(currentPageNumber);
  const [isSeriesFetching, setIsSeriesFetching] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [rejectionReasonField, setRejectionReasonField] = useState("");
  const [selectedCreator, setSelectedCreator] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  // const [showCreate, setShowCreate] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [reviewStatusFilter, setReviewStatusFilter] = useState([]);
  const [premiumFilter, setPremiumFilter] = useState([]);
  const [promotedSeriesFilter, setPromotedSeriesFilter] = useState([]);
  const [originalSeriesFilter, setOriginalSeriesFilter] = useState([]);
  const [contentManagerFilter, setContentManagerFilter] = useState([]);
  const [languageFilter, setLanguageFilter] = useState([]);
  const [isDubbedFilter, setDubbedFilter] = useState([]);
  const [isNews, setIsnewsFilter] = useState([]);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [errorForScheduling, setErrorForScheduling] = useState([]);
  const [selectScheduleActive, setSelectScheduleActive] = useState(false);
  const [scheduleRowSlug, setScheduleRowSlug] = useState("");
  const [scheduledData, setScheduledData] = useState({});
  const [errorFetch, setErrorFetching] = useState(false);
  const [errorMsg, setErrorMsg] = useState({ error: "", type: "success" });
  const [selectedScheduleSeries, setSelectedScheduleSeries] = useState([]);
  const [showRejectionReasonModal, setShowRejectionReasonModal] =
    useState(false);
  const [currentActiveTab, setCurrentTab] = React.useState(0);
  const selectedQuickFilterSelector = useSelector((state) => {
    return state.series.selectedTabForSeriesPage;
  });
  const [selectedQuickFilter, setQuickFilterTo] = useState(
    selectedQuickFilterSelector
  );
  const [showVideo, setShowVideo] = useState(false);
  const [showVideosLoading, setShowVideosLoading] = useState(false);
  const [seriesVideos, setSeriesVideos] = useState([]);
  const [searchedName, setSearchedName] = useState("");
  const [autoplay, setAutoplay] = useState(false);
  const [videoSource, setVideoSource] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);
  const reasonData = useSelector((state) => {
    return state.series.rejectionReasonData;
  });
  const [rejectedSeriesSlug, setRejectedSeriesSlug] = useState("");
  const [
    showRejectionReasonsListForSeriesModal,
    setShowRejectionReasonsListForSeriesModal,
  ] = useState(false);
  const [message, setMessage] = useState("");
  const [hasMoreVideos, setHasMoreVideos] = useState(false);
  // const commonData = useSelector((state) => state.common);
  const errorFetching = useSelector((state) => state.series.errorMessage);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const errorMessageOnLatestAction = useSelector(
    (state) => state.series.errorMessage
  );
  const [os, setOs] = useState(0);
  const [playIndex, setPlayIndex] = useState(0);
  const isUpdatingSeries = useSelector((state) => state.series.isUpdating);
  const isFetching = useSelector((state) => state.series.isFetching);
  const searchedString = useSelector((state) => state.series.searchedString);
  const user = useSelector((state) => state.user.currentUser);
  const sortKey = useSelector((state) => state.series.sortKey);
  const sortReverse = useSelector((state) => state.series.sortReverse);
  const seriesPageData = useSelector((state) => state.series?.page_data);
  const selectedCreatorSeriesFilter = useSelector(
    (state) => state.series.selectedCreatorSeriesFilter
  );
  const {
    openCreateSeriesModal,
    globalRoles,
    internalTeamAccessGlobalState,
    setOpenCreateSeriesModal,
  } = useZustandStore();
  const isViewer = globalRoles?.viewer;
  const isSuperAdmin = globalRoles?.superAdmin;
  const isAdmin = globalRoles?.admin;
  const isCreator = globalRoles?.creator;
  const hasAccessToCreate = internalTeamAccessGlobalState || isCreator;
  const hasAccessToActions = internalTeamAccessGlobalState;

  const onRejectionFieldChange = (e) => {
    setRejectionReasonField(e.target.value);
  };

  // components applied filter State
  useEffect(() => {
    if (searchedString) {
      setSearchedName(searchedString);
      setShowSearch(true);
    }
  }, [isUpdatingSeries, searchedString]);

  useEffect(() => {
    if (searchedString && !remainingProps["isOnCreatorProfilePage"]) {
      setSearchedName("");
      setShowSearch(false);
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setCurrentTab(newValue);
    handleSelectedQuickFiltersApply(
      quickFiltersSeries[
        quickRowFilters.filter((d) => d.index == newValue)[0]?.key
      ]
    );
  };

  useEffect(() => {
    async function fetchMore() {
      const filters = selectedSeriesFilters;
      if (!remainingProps["isOnCreatorProfilePage"]) {
        filters.set("creatorFilter", []);
      } else {
        filters.set("creatorFilter", [remainingProps["creatorDetails"]]);
      }
      if (remainingProps["isOnShowPage"] && remainingProps["showDetail"]) {
        filters.set("showFilter", [remainingProps["showDetail"]?.slug]);
      } else {
        filters.set("showFilter", []);
      }
      if (!isFetching)
        await dispatch(
          fetchSeriesStartAsync(
            axiosPrivate,
            pageNumber <= 0 ? 1 : pageNumber,
            searchName,
            filters,
            sortKey,
            sortReverse,
            selectedCreatorSeriesFilter,
            setErrorMsg
          )
        );
    }
    if (
      pageNumber > 0 &&
      currentActiveTab >= 0 &&
      isSeriesFetching.toString()?.length > 0
    ) {
      fetchMore();
    }
  }, [pageNumber, currentActiveTab, isSeriesFetching]);

  useEffect(() => {
    if (selectedCreator) {
      setPageNumber(1);
      setIsLoading(false);
    }
  }, [selectedCreator]);

  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleSearch();
    }
  };

  // component did mount founded using empty parameter
  useEffect(() => {
    const filters = selectedSeriesFilters;
    let indexValue = quickRowFilters.filter(
      (d) => d.key == selectedQuickFilter
    )[0]?.index;

    if (remainingProps["isOnCreatorProfilePage"]) {
      initializeSeries();
      filters.set("creatorFilter", [remainingProps["creatorDetails"]]);
      setIsLoading(false);
    }
    if (indexValue == undefined || indexValue == null) {
      handleChangeTabs({}, 0);
    } else {
      if (remainingProps["isOnCreatorProfilePage"]) {
        handleChangeTabs(
          {},
          indexValue == 5 || indexValue == 6 ? 0 : indexValue
        );
      } else {
        handleChangeTabs({}, indexValue);
      }
    }
    if (history.location.state) {
      const seriesCreator = history.location.state.seriesCreator;
      setSelectedCreator(seriesCreator);
      filters.set("creatorFilter", [seriesCreator]);
      setSeriesFilters(filters);
    } else if (
      selectedSeriesFilters.get("creatorFilter") &&
      selectedSeriesFilters.get("creatorFilter").length !== 0
    ) {
      setSelectedCreator(selectedSeriesFilters.get("creatorFilter")[0]);
      setIsLoading(false);
    } else {
      setSelectedCreator(null);
      setIsLoading(false);
    }
    // setPageNumber(1);
  }, []);

  const handleSort = (sortingKey) => {
    setHasMoreSeries(true);
    if (sortingKey === sortKey) {
      dispatch(
        fetchSeriesStartAsync(
          axiosPrivate,
          pageNumber <= 0 ? 1 : pageNumber,
          searchName,
          selectedSeriesFilters,
          sortingKey,
          !sortReverse,
          selectedCreatorSeriesFilter,
          setErrorMsg
        )
      );
    } else {
      dispatch(
        fetchSeriesStartAsync(
          axiosPrivate,
          pageNumber <= 0 ? 1 : pageNumber,
          searchName,
          selectedSeriesFilters,
          sortingKey,
          !sortReverse,
          selectedCreatorSeriesFilter,
          setErrorMsg
        )
      );
    }
  };

  const hideAction = () => {
    if (showAction && selectedSeries !== null) {
      setShowAction(false);
    }
  };

  const applyFilter = () => {
    setPageNumber((prev) => {
      return 1;
    });
    setShowFilter(false);
    setIsSeriesFetching(!isSeriesFetching);
  };

  const handleChange = (e) => {
    setSearchName(e.target.value);
  };

  const handleSearch = (e) => {
    if (searchName.length < 3) {
      setErrorMsg({
        type: "failed",
        error: "Enter minimum 3 characters for search",
      });
      return;
    }
    setPageNumber(1);
    setShowSearch(true);
    setSearchedName(searchName);
    setIsSeriesFetching(!isSeriesFetching);
  };

  const removeSearch = () => {
    setSearchName("");
    setSearchedName("");
    setShowSearch(false);
    setPageNumber(1);
    setIsSeriesFetching(!isSeriesFetching);
  };

  const handleFetchSeriesAfterCreated = () => {
    dispatch(
      fetchSeriesStartAsync(
        axiosPrivate,
        pageNumber <= 0 ? 1 : pageNumber,
        searchName,
        selectSeriesFilters,
        sortKey,
        sortReverse,
        selectedCreatorSeriesFilter,
        setErrorMsg
      )
    );
  };

  const rejectReasonSubmit = () => {
    if (rejectionReasonField != "") {
      dispatch(
        changeSeriesStatusAsync(
          axiosPrivate,
          rejectedSeriesSlug,
          "changes_required",
          rejectionReasonField,
          setIsSeriesFetching,
          isSeriesFetching,
          setErrorMsg
        )
      );
      setShowRejectionReasonModal(false);
    } else {
      setMessage("Please fill the reason !");
      setErrorMsg({
        type: "failed",
        error: "Please fill the reason !",
      });
    }
  };
  /**
   * On click button for Quick Filters button
   *
   * @param {selectedQuickFilter} - It is a state to
   *
   * Output : Handles the filters operation in quick filters
   */
  const handleSelectedQuickFiltersApply = (selectedQuickFilter) => {
    setQuickFilterTo(selectedQuickFilter);
    // Setting the state in redux
    dispatch(updateSelectedTab(selectedQuickFilter));
    const filters = selectedSeriesFilters;
    let promotedSeriesFilter = filters.get("promotedSeriesFilter");
    let isPremiumFilter = filters.get("isPremiumFilter");
    promotedSeriesFilter[0] = false;
    isPremiumFilter[0] = false;

    const statusFilterToApply = [];
    // PROMOTED
    if (selectedQuickFilter === quickFilters.PROMOTED_SERIES) {
      //logic to set promoted series filter inside filter
      if (promotedSeriesFilter[0] !== true) {
        promotedSeriesFilter[0] = true;
        isPremiumFilter[0] = false;
      }
      setPromotedSeriesFilter(promotedSeriesFilter);
    }
    // PREMIUM
    else if (selectedQuickFilter === quickFilters.PREMIUM_SERIES) {
      if (isPremiumFilter[0] !== true) {
        promotedSeriesFilter[0] = false;
        isPremiumFilter[0] = true;
      }

      setPremiumFilter(isPremiumFilter);
    }
    // STATUS
    else if (selectedQuickFilter === quickFiltersSeries.DRAFT) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.DRAFT);
    } else if (selectedQuickFilter === quickFiltersSeries.SCHEDULED) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.SCHEDULED);
    } else if (selectedQuickFilter === quickFiltersSeries.LIVE) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.LIVE);
    } else if (selectedQuickFilter === quickFiltersSeries.UNDER_REVIEW) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.UNDER_REVIEW);
    } else if (selectedQuickFilter === quickFiltersSeries.CHANGES_REQUIRED) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.CHANGES_REQUIRED);
    } else if (selectedQuickFilter === quickFiltersSeries.DELETED) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.DELETED);
    } else if (selectedQuickFilter === quickFiltersSeries.EXPIRED) {
      statusFilterToApply.push(quickFiltersSeriesValueMapping.EXPIRED);
    }
    setSelectScheduleActive(false);
    setSelectedScheduleSeries([]);
    filters.set("promotedSeriesFilter", promotedSeriesFilter);
    filters.set("isPremiumFilter", isPremiumFilter);
    filters.set("statusFilter", statusFilterToApply);
    // Set filters redux state
    setSeriesFilters(filters);
    setIsnewsFilter([]);
    dispatch(setSelectedCreatorSeriesFilter(filters));
    // Reload the table
    setPageNumber(1);
    setIsSeriesFetching(true);
  };

  const handleSelectFilter = (e) => {
    const filters = selectedSeriesFilters;
    const filterType = e.target.name;
    const isChecked = e.target.checked;

    // Check the name of the checkbox
    if (filterType === "categoryFilter") {
      const categoryId = e.target.value;
      // get the category filter from filters
      let categoryFilter = filters.get("categoryFilter");
      // Toggle to check whether the event is checked
      if (isChecked) {
        // event is checked then add the category id, that is checkbox value to the category Filter
        categoryFilter = categoryFilter.concat(categoryId);
      } else {
        // Remove the id from the category filter
        categoryFilter = categoryFilter.filter((id) => categoryId !== id);
        // UPDATE THE TEMP STATE FILTERS OF THE PAGE
      }
      // Set the category Filter
      setCategoryFilter(categoryFilter);
      // Set the filter that will be later used to set the selected filter
      filters.set("categoryFilter", categoryFilter);
    } else if (filterType === "statusFilter") {
      const status = e.target.value;
      let statusFilter = filters.get("statusFilter");
      if (isChecked) {
        statusFilter = statusFilter.concat(status);
      } else {
        statusFilter = statusFilter.filter((value) => status !== value);
      }
      setStatusFilter(statusFilter);
      filters.set("statusFilter", statusFilter);
    } else if (filterType === "reviewStatusFilter") {
      const reviewStatus = e.target.value;
      let reviewStatusFilter = filters.get("reviewStatusFilter");
      if (isChecked) {
        reviewStatusFilter = reviewStatusFilter.concat(reviewStatus);
      } else {
        reviewStatusFilter = reviewStatusFilter.filter(
          (value) => reviewStatus !== value
        );
      }
      setReviewStatusFilter(reviewStatusFilter);
      filters.set("reviewStatusFilter", reviewStatusFilter);
    } else if (filterType === "isPremiumFilter") {
      const isPremium = e.target.value;
      let isPremiumFilter = filters.get("isPremiumFilter");
      if (isChecked) {
        isPremiumFilter = isPremiumFilter.concat(isPremium);
      } else {
        isPremiumFilter = isPremiumFilter.filter(
          (value) => isPremium !== value
        );
      }
      setPremiumFilter(isPremiumFilter);

      filters.set("isPremiumFilter", isPremiumFilter);
    } else if (filterType === "promotedSeriesFilter") {
      const isPromoted = e.target.value;
      let promotedSeriesFilter = filters.get("promotedSeriesFilter");
      if (isChecked) {
        promotedSeriesFilter = promotedSeriesFilter.concat(isPromoted);
      } else {
        promotedSeriesFilter = promotedSeriesFilter.filter(
          (value) => isPromoted !== value
        );
      }

      setPromotedSeriesFilter(promotedSeriesFilter);

      filters.set("promotedSeriesFilter", promotedSeriesFilter);
    } else if (filterType === "isOriginalFilter") {
      const isOriginal = e.target.value;
      let isOriginalFilter = filters.get("isOriginalFilter");
      if (isChecked) {
        isOriginalFilter = isOriginalFilter.concat(isOriginal);
      } else {
        isOriginalFilter = isOriginalFilter.filter(
          (value) => isOriginal !== value
        );
      }

      setOriginalSeriesFilter(isOriginalFilter);
      filters.set("isOriginalFilter", isOriginalFilter);
    } else if (filterType === "notificationFilter") {
      const isNotification = e.target.value;
      let notificationFilter = filters.get("notificationFilter");
      if (isChecked) {
        if (isNotification == "inactive") {
          notificationFilter = ["inactive"];
        } else notificationFilter = notificationFilter.concat(isNotification);
      } else {
        notificationFilter = notificationFilter.filter(
          (value) => isNotification !== value
        );
      }
      setOriginalSeriesFilter(notificationFilter);
      filters.set("notificationFilter", notificationFilter);
    } else if (filterType === "contentManagerFilter") {
      const contentManagerId = e.target.value;

      let contentManagerFilter = filters.get("contentManagerFilter");
      if (isChecked) {
        contentManagerFilter = contentManagerFilter.concat(contentManagerId);
      } else {
        contentManagerFilter = contentManagerFilter.filter(
          (value) => contentManagerId !== value
        );
      }
      setContentManagerFilter(contentManagerFilter);
      filters.set("contentManagerFilter", contentManagerFilter);
    } else if (filterType === "languageFilter") {
      const language = e.target.value;
      let languageFilter = filters.get("languageFilter");
      if (isChecked) {
        languageFilter = [language];
      } else {
        languageFilter = languageFilter.filter((value) => language !== value);
      }

      setLanguageFilter(languageFilter);
      filters.set("languageFilter", languageFilter);
    } else if (filterType === "dubbedFilter") {
      const dubbed = e.target.value;
      let dubbedFilter = filters.get("dubbedFilter");
      if (isChecked) {
        dubbedFilter = [dubbed];
      } else {
        dubbedFilter = dubbedFilter.filter((value) => dubbed !== value);
      }
      setDubbedFilter(dubbedFilter);
      filters.set("dubbedFilter", dubbedFilter);
    } else if (filterType === "newsFilter") {
      const is_news = e.target.value;
      let newsFilter = filters?.get("newsFilter");
      if (isChecked) {
        newsFilter = [is_news];
      } else {
        newsFilter = newsFilter.filter((value) => is_news !== value);
      }
      setIsnewsFilter(newsFilter);
      filters.set("newsFilter", newsFilter);
    }

    // Changed the filter completed now update the set series filters
    setSeriesFilters(filters);
  };

  const toggleCreate = (checkNotification) => {
    if (checkNotification == true) {
      setErrorMsg({
        type: "success",
        error: "series created successfully",
      });
    }
    setOpenCreateSeriesModal(false);
  };
  const toggleRejectionSeriesList = () => {
    setShowRejectionReasonsListForSeriesModal((prev) => !prev);
  };

  const toggleRejection = () => {
    setShowRejectionReasonModal((prev) => !prev);
  };

  const getLabelFromFilterValue = (valueId, keyName) => {
    if (keyName === "categoryFilter") {
      const backendKeywordForFilter = filterKeywordToBackendKeywords(keyName);
      const categoriesFilter =
        configFilters[backendKeywordForFilter.toString()];

      const nameOfVariable = categoriesFilter?.map((data) => {
        if (data?.id == valueId) {
          return data?.title;
        }
      });

      return nameOfVariable;
    } else if (keyName === "notificationFilter") {
      let value = valueId?.split("_");
      return value.join(" ");
    } else if (keyName === "contentManagerFilter") {
      const backendKeywordForFilter = filterKeywordToBackendKeywords(
        "contentManagerFilter"
      );
      const contentManagerFilter =
        configFilters[backendKeywordForFilter.toString()];

      const nameOfVariable = contentManagerFilter?.map((data) => {
        if (data.id == valueId) {
          return data.name;
        }
      });

      return nameOfVariable;
    } else {
      return valueId;
    }
  };

  // REMOVE BUTTON FOR FILTER CHIPS
  const removeFromSelectedFilter = async (id) => {
    const filters = selectedSeriesFilters;
    // HERE IN THIS FUNCTION BOOLEAN FILTERS NEED TO BE HANDLED SEPERATELY.
    if (id === "isOriginalFilter") {
      const originalFilterTemp = filters.get("isOriginalFilter");

      filters.set("isOriginalFilter", []);
    }
    if (id === "notificationFilter") {
      const notificationFilterTemp = filters.get("notificationFilter");
      filters.set("notificationFilter", []);
    }
    if (id === "languageFilter") {
      const languageFilterTemp = filters.get("languageFilter");
      filters.set("languageFilter", []);
    }
    if (id === "dubbedFilter") {
      const dubbedFilterTemp = filters.get("dubbedFilter");
      filters.set("dubbedFilter", []);
    }
    // Parse through selected filters and filter out the selected series filters
    for (const [key, value] of filters) {
      if (value.length > 0 && key === "categoryFilter") {
        const newValue = value.filter((itemId) => itemId !== id);
        filters.set(key, newValue);
      }
      if (value.length > 0) {
        const newValue = value.filter((itemId) => itemId !== id);
        filters.set(key, newValue);
      }
    }
    // This const filter is linked to the checkbox
    applyFilter();
  };

  // MAP THE SELECTED SERIES FILTER TO CHIPS
  const chipsFromSelectedSeries = () => {
    const items = [];
    for (const [key, value] of selectedSeriesFilters) {
      if (key === "isOriginalFilter") {
        if (value[0]) {
          return (
            <Chip
              label="Only Original"
              className="m-2 p-2"
              variant="filled"
              color="secondary"
              value={value}
              onDelete={() => {
                removeFromSelectedFilter(key);
              }}
            />
          );
        }
      }
      if (key === "languageFilter") {
        if (value[0]) {
          return (
            <Chip
              label={value[0] == "bn" ? "Bangla" : "Hindi"}
              className="m-2 p-2"
              variant="filled"
              color="secondary"
              value={value}
              onDelete={() => {
                removeFromSelectedFilter(key);
              }}
            />
          );
        }
      }
      if (key === "dubbedFilter") {
        if (value[0]) {
          return (
            <Chip
              label={value[0] == "true" ? "Dubbed" : "Original"}
              className="m-2 p-2"
              variant="filled"
              color="secondary"
              value={value}
              onDelete={() => {
                removeFromSelectedFilter(key);
              }}
            />
          );
        }
      }
      if (key === "notificationFilter") {
        var item = value?.map((value_item, i) => {
          return (
            <Chip
              label={`${value_item?.split("_")[0]} ${
                value_item?.split("_")[1]
              }`}
              key={i}
              className="m-2 p-2"
              variant="filled"
              color="secondary"
              value={value_item}
              onDelete={() => {
                removeFromSelectedFilter(key);
              }}
            />
          );
        });
      }
      if (key === "creatorFilter" && value.length !== 0) {
        var item = value?.map((value_item, i) => {
          return (
            <Chip
              label={value_item.name}
              className="m-2 p-2"
              key={i}
              variant="filled"
              color="success"
              value={value_item.id}
            />
          );
        });
      } else if (
        value.length > 0 &&
        key !== "statusFilter" &&
        key !== "showFilter"
      ) {
        var item = value?.map((id, i) => {
          // Remove the boolean and true values of the chip for promoted and premium series
          if (typeof id != "boolean" && id !== "true") {
            return (
              <Chip
                label={getLabelFromFilterValue(id, key)}
                className="m-2 p-2 chip-hardcode"
                variant="filled"
                color="success"
                value={id}
                key={i}
                onDelete={() => {
                  removeFromSelectedFilter(id);
                }}
              />
            );
          }
        });
        items.push(item);
      }
    }

    return items;
  };

  // Get Rejection data for the series
  const renderRejectedReasonData = () => {
    if (reasonData != undefined) {
      if (reasonData.reasons.length > 0) {
        const finalReasons = reasonData.reasons?.map((d, i) => {
          return (
            <span key={i}>
              <ListItem>
                <ListItemText
                  primary={d.reason}
                  secondary={handleFormatDate({ dateInString: d.rejected_on })}
                ></ListItemText>
              </ListItem>
              <Divider className="divier-custom-color" component="li" />
            </span>
          );
        });
        const listToReturn = <List>{finalReasons}</List>;
        return listToReturn;
      } else {
        return <li>No logs for rejection reasons</li>;
      }
    }
    return <li>No logs for rejection reasons</li>;
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowFilter(!showFilter);
  };

  const handleSelectVideo = (e, type) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if (type == "all") {
        let dataArray = [];
        series?.forEach((element) => {
          dataArray.push(element?.slug);
        });
        setSelectedScheduleSeries((prev) => dataArray);
      } else {
        setSelectedScheduleSeries((prev) => [...prev, e.target.value]);
      }
    } else {
      if (type == "all") {
        setSelectedScheduleSeries((prev) => []);
      } else {
        setSelectedScheduleSeries((prev) =>
          prev.filter((videoId) => videoId !== e.target.value)
        );
      }
    }
  };

  const setUpScheduleDate = async (data, type, edit) => {
    let url = `${apiGateway}${SERIES_SCHEDULE_V1}`;
    let dynamicFormDate;
    if (type === "custom") {
      dynamicFormDate = new Date(data).toLocaleString();
    } else {
      dynamicFormDate = new Date(
        `${data?.value?.split("/")[0]}-${data?.value?.split("/")[1]}-${
          data?.value?.split("/")[2]
        }`
      );
    }

    const dynamicDate =
      type === "custom"
        ? `${dynamicFormDate?.split(",")[0]?.split("/")[2]}-${
            dynamicFormDate?.split(",")[0]?.split("/")[1]
          }-${dynamicFormDate?.split(",")[0]?.split("/")[0]}T${
            dynamicFormDate?.split(", ")[1]
          }+05:30`
        : `${dynamicFormDate.getFullYear()}-${
            dynamicFormDate.getMonth() + 1
          }-${dynamicFormDate.getDate()}T18:00:00+05:30`;

    let formData = new FormData();
    if (edit?.length > 0) {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slug", scheduleRowSlug);
    } else {
      formData.append("schedule_on", dynamicDate);
      formData.append("series_slugs", JSON.stringify(selectedScheduleSeries));
    }
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        // if(status === 200 && data?.failure_slugs?.length === 0 && data?.success_count === selectedScheduleSeries?.length)
        if (status === 200) {
          setErrorMsg({ error: "Scheduled successfully", type: "success" });
          setOpenScheduleModal(false);
          setSelectScheduleActive((prev) => !prev);
          setIsSeriesFetching(!isSeriesFetching);
          dispatch(
            fetchSeriesStartAsync(
              axiosPrivate,
              pageNumber <= 0 ? 1 : pageNumber,
              searchName,
              selectSeriesFilters,
              sortKey,
              sortReverse,
              selectedCreatorSeriesFilter,
              setErrorMsg
            )
          );
        } else {
          setErrorForScheduling(data?.failure_slugs);
        }
        if (data?.message?.length > 0) {
          setErrorMsg({ error: data?.message, type: "failed" });
          setOpenScheduleModal(false);
          setSelectScheduleActive((prev) => !prev);
        }
        setPageNumber(1);
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.message
            : "Unable to create scheduling",
          type: "failed",
        });
        setOpenScheduleModal(false);
        setSelectScheduleActive((prev) => !prev);
      });
  };

  const deleteSchedule = async (data) => {
    let url = `${apiGateway}${GET_SERIES_V1}${data}/schedule/delete/`;
    axiosPrivate
      .delete(url)
      .then(({ data, status }) => {
        if (data?.error_code?.length > 0 || status !== 200) {
          setErrorMsg({
            error: data?.error_message ?? "Failed to delete",
            type: "failed",
          });
        } else {
          setErrorMsg({ error: data?.message, type: "success" });
          setPageNumber(1);
          setIsSeriesFetching(!isSeriesFetching);
        }
        if (data?.message?.length > 0 && data?.status !== "success") {
          setErrorMsg({ error: data?.message, type: "failed" });
        }
      })
      .catch(({ response }) => {
        setErrorMsg({
          error: response?.data
            ? response?.data?.message
            : "Unable to remove scheduling",
          type: "failed",
        });
      });
  };

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const fetchMoreVideo = async (page, slug) => {
    setShowVideo(true);
    setShowVideosLoading(true);
    const url = `${apiGateway}${SERIES_V1_1}${slug}/units/?page=${1}&lang=en&page_size=${page}`;
    axiosPrivate
      .get(url)
      .then(({ data, status }) => {
        if (data && status === 200) {
          setHasMoreVideos(data?.has_more);
          if (data?.content_units) {
            setSeriesVideos([...data.content_units]);
            const video = data.content_units[0];
            if (video) setSelectedVideo((prev) => video);
            setAutoplay(true);
            setPlayIndex(0);
            playVideoNext(0, data.content_units);
          }
          if (errorFetching) {
            setErrorFetching(false);
          }
        }
        setShowVideosLoading(false);
      })
      .catch((err) => {
        setErrorFetching(true);
        setShowVideosLoading(false);
      });
  };

  const playAllVideos = (n_unit, s_slug) => {
    fetchMoreVideo(n_unit, s_slug);
  };

  const playVideoNext = async (currentPlayIndex, data) => {
    const index = currentPlayIndex >= 0 ? currentPlayIndex : playIndex + 1;
    if (index >= 0 && index < data?.length) {
      const video = data[index];
      setSelectedVideo((prev) => video);
      const source = video?.content?.h264_media_url
        ? video?.content?.h264_media_url
        : video?.content?.url;
      setVideoSource((prev) => source);
      setVideoTitle((prev) => video?.title);
      setPlayIndex((prev) => index);
      setShowVideo(true);
    } else if (hasMoreVideos) {
      setPageNumber((prev) => pageNumber + 1);
    }
  };

  const playVideoPrev = () => {
    const index = playIndex - 1;
    if (index >= 0 && index < seriesVideos?.length) {
      const video = seriesVideos[index];
      setSelectedVideo((prev) => video);
      setVideoSource(video.content.h264_media_url);
      setVideoTitle(video?.title);
      setPlayIndex(index);
      setShowVideo(true);
    }
  };

  const onVideoEnd = (seriesVideos) => {
    playVideoNext(-1, seriesVideos);
  };
  const getDateTimeFormat = (date) => {
    const newDate = moment(date).format("DD MM YYYY");
    return newDate?.split(" ").join(" - ");
  };

  const handleVideoModal = (videoSource, videoTitle, playIndex) => {
    setAutoplay(true);
    if (showVideo) {
      setShowVideo(false);
      setPlayIndex(0);
      setVideoSource(false);
      return;
    } else {
      setVideoSource(videoSource);
      setVideoTitle(videoTitle);
      setShowVideo((prev) => !prev);
      setPlayIndex(playIndex);
      playerRef.current = null;
    }
  };

  useEffect(() => {
    const detectedOs = detectOS();
    setOs(detectedOs);
  }, []);

  const detectOS = () => {
    const platform = navigator.platform;
    if (platform.indexOf("Win") !== -1) return 1;
    if (platform.indexOf("Mac") !== -1) return 0;
    if (platform.indexOf("Linux") !== -1) return 1;
    if (platform.indexOf("iPhone") !== -1) return 0;
    if (platform.indexOf("Android") !== -1) return 1;
    if (platform.indexOf("iPad") !== -1) return 0;
    return "Unknown";
  };

  const handleAnalytics = (id) => {
    window.location.href = `#/series-dashboard/${id}`;
  };

  return (
    <div className="series-list">
      {hasAccessToCreate && (
        <div>
          {!(
            remainingProps["isOnCreatorProfilePage"] ||
            remainingProps["isOnShowPage"]
          ) && (
            <div
              className="cta-button-create"
              onClick={() => setOpenCreateSeriesModal(true)}
            >
              <AddIcon fontSize="large" className="icon-plus-cta" />
              <span>Create new episode</span>
            </div>
          )}
        </div>
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <AlertComponent message={errorMessageOnLatestAction} type="failed" />
          <AlertComponent
            message={errorMsg?.error}
            type={errorMsg?.type}
            setAlertNotification={() =>
              setErrorMsg({ error: "", type: errorMsg?.type })
            }
          />
          <div onClick={hideAction}>
            <div className="page-header-series">
              <Paper elevation={0} className="search-paper mb-2">
                {/* Search box */}
                <div className="mt-2">
                  <div className="container-search-box">
                    <div className="creator-search serieslist-search-col custom-searchbox">
                      <input
                        label="Search"
                        type="search"
                        onChange={handleChange}
                        minLength="8"
                        onKeyPress={handleInputKey}
                        value={searchName}
                        placeholder="Enter Series Name or ID..."
                        className="search-box-input-serieslist bg-white  w-100"
                      />
                      <FontAwesomeIcon
                        className="search-icon bg-white pb-1"
                        icon={faSearch}
                        color="black"
                        size="2x"
                        onClick={handleSearch}
                      />
                    </div>
                    <div className="action-button-group">
                      <Button
                        size="small"
                        variant="contained"
                        className="filters-button float-right custom-filter-button"
                        onClick={() => setShowFilter((prev) => !prev)}
                      >
                        <FontAwesomeIcon icon={faFilter} /> FILTERS
                      </Button>

                      {globalRoles?.creator && (
                        <Button
                          size="small"
                          variant="contained"
                          className="filters-button float-right custom-filter-button !bg-[#534f8f] !text-white"
                          onClick={() => setOpenCreateSeriesModal(true)}
                        >
                          <FontAwesomeIcon icon={faUpload} /> Upload Episode
                        </Button>
                      )}

                      {selectedQuickFilter ===
                        quickFiltersSeries.UNDER_REVIEW &&
                        (isAdmin || isSuperAdmin) && (
                          <Button
                            size="small"
                            variant="contained"
                            className="filters-button float-right custom-filter-button scheduled-cta"
                            onClick={() =>
                              !selectScheduleActive
                                ? setSelectScheduleActive((prev) => !prev)
                                : selectedScheduleSeries?.length > 0 &&
                                  setOpenScheduleModal(!openScheduleModal)
                            }
                          >
                            {!selectScheduleActive
                              ? "Select & Schedule"
                              : "Schedule Publish"}
                          </Button>
                        )}
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
            <Drawer
              anchor="right"
              open={showFilter}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 350, overflow: "hidden", paddingLeft: 1 }}
                role="presentation"
              >
                <div className="filters-series box-filter-drawer">
                  <div className="original-list">
                    <h5 className="filter-header">Language </h5>
                    <Form.Group className="filters-list-series custom-drawer-list-series">
                      {configFilters.language_filter?.map((lang, i) => {
                        return (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            name="languageFilter"
                            className="form-col col-md-6 form-checkbox-align"
                            onChange={handleSelectFilter}
                            checked={
                              selectedSeriesFilters
                                .get("languageFilter")
                                .indexOf(lang.value.toString()) > -1
                                ? true
                                : false
                            }
                            value={lang.value}
                            label={lang.label}
                            id={lang.label}
                          ></Form.Check>
                        );
                      })}
                    </Form.Group>
                  </div>
                  <div className="original-list">
                    <h5 className="filter-header">News/Series </h5>
                    <Form.Group className="filters-list-series custom-drawer-list-series">
                      {configFilters.is_news_filter?.map((item, i) => {
                        return (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            name="newsFilter"
                            className="form-col col-md-6 form-checkbox-align"
                            onChange={handleSelectFilter}
                            checked={
                              selectedSeriesFilters
                                ?.get("newsFilter")
                                ?.indexOf(item.value.toString()) > -1
                                ? true
                                : false
                            }
                            value={item.value}
                            label={item.label}
                            id={item.label}
                          ></Form.Check>
                        );
                      })}
                    </Form.Group>
                  </div>
                  <div className="original-list">
                    <h5 className="filter-header">Dubbed series </h5>
                    <Form.Group className="filters-list-series custom-drawer-list-series">
                      {configFilters.is_dubbed_filter?.map((item, i) => {
                        return (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            name="dubbedFilter"
                            className="form-col col-md-6 form-checkbox-align"
                            onChange={handleSelectFilter}
                            checked={
                              selectedSeriesFilters
                                .get("dubbedFilter")
                                .indexOf(item.value.toString()) > -1
                                ? true
                                : false
                            }
                            value={item.value}
                            label={item.label}
                            id={item.label}
                          ></Form.Check>
                        );
                      })}
                    </Form.Group>
                  </div>
                  <div className="categories-list-series">
                    <h5 className="filter-header">Categories</h5>
                    <Form.Group
                      className="filters-list-series custom-drawer-list-series"
                      as={Row}
                    >
                      {configFilters.categories?.map((category, index) => {
                        return (
                          <Form.Check
                            key={category.id + `${index}`}
                            type="checkbox"
                            name="categoryFilter"
                            onChange={handleSelectFilter}
                            id={category?.title}
                            value={category?.id}
                            label={category?.title}
                            className="form-col col-md-6 form-checkbox-align"
                            checked={
                              selectedSeriesFilters
                                .get("categoryFilter")
                                .indexOf(category.id.toString()) > -1
                                ? true
                                : false
                            }
                          ></Form.Check>
                        );
                      })}
                    </Form.Group>
                  </div>
                  <div className="notification-list-series">
                    <h5 className="filter-header">Notification</h5>
                    <Form.Group
                      className="filters-list-series custom-drawer-list-series"
                      // as={Row}
                    >
                      {configFilters?.notificationType?.map(
                        (notification, index) => {
                          return (
                            <Form.Check
                              key={index}
                              disabled={
                                selectedSeriesFilters
                                  .get("notificationFilter")
                                  ?.includes("inactive") &&
                                notification?.value !== "inactive"
                              }
                              type="checkbox"
                              name="notificationFilter"
                              onChange={handleSelectFilter}
                              id={notification?.label}
                              value={notification?.value}
                              label={notification?.label}
                              className="form-col col-md-6 form-checkbox-align"
                              checked={
                                selectedSeriesFilters
                                  .get("notificationFilter")
                                  .indexOf(notification?.value.toString()) > -1
                                  ? true
                                  : false
                              }
                            ></Form.Check>
                          );
                        }
                      )}
                    </Form.Group>
                  </div>
                  <div className="original-list">
                    {/* Review Status is removed for now */}
                    <h5 className="filter-header">Original </h5>
                    <Form.Group className="filters-list-series custom-drawer-list-series">
                      {configFilters.isOriginal?.map((original, i) => {
                        return (
                          <Form.Check
                            key={i}
                            type="checkbox"
                            name="isOriginalFilter"
                            className="form-col col-md-6 form-checkbox-align"
                            onChange={handleSelectFilter}
                            checked={
                              selectedSeriesFilters
                                .get("isOriginalFilter")
                                .indexOf(original.value.toString()) > -1
                                ? true
                                : false
                            }
                            value={original.value}
                            label={original.label}
                            id={original.label}
                          ></Form.Check>
                        );
                      })}
                    </Form.Group>
                  </div>
                </div>
                <div className="action-button-filter">
                  {/* APPLY FILTER BUTTON */}
                  {showFilter && (
                    <Button
                      variant="contained"
                      onClick={applyFilter}
                      className="apply-button"
                    >
                      APPLY
                    </Button>
                  )}
                </div>
              </Box>
            </Drawer>
            <div className="full-layout-scroll-enable" id="HideScroll">
              {/* SEARCHED NAME CHIPS*/}
              {showSearch && (
                <span className="chip-name">
                  <Chip
                    size="large"
                    className="my-2 p-1"
                    color="secondary"
                    label={searchedName}
                    variant="filled"
                    onDelete={removeSearch}
                  />
                </span>
              )}
              {/* Chip close for filter applied */}
              {!showFilter && <span>{chipsFromSelectedSeries()} </span>}
              {/* QUICK FILTERS*/}
              <div className="p-0">
                <div className="card-panel">
                  <Box sx={{ borderBottom: 2, borderColor: "divider" }}>
                    <Tabs
                      value={currentActiveTab}
                      onChange={handleChangeTabs}
                      aria-label="basic tabs example"
                    >
                      {quickRowFilters?.map(
                        (data, i) =>
                          // remainingProps["isOnCreatorProfilePage"]
                          //   ? data.key !== "SCHEDULED" && (
                          //       <Tab label={data?.id} {...a11yProps(i)} key={i} />
                          //     )
                          //   :
                          data?.show ===
                            (internalTeamAccessGlobalState || isViewer) && (
                            <Tab label={data?.id} {...a11yProps(i)} key={i} />
                          )
                      )}
                    </Tabs>
                  </Box>
                  <Card
                    className="shadow-table"
                    style={{ border: "1px solid var(--search_chip)" }}
                  >
                    {/* Table for displaying series data */}
                    {hasMoreCreators && isCreatorFetching ? (
                      <LinearProgress color="inherit" />
                    ) : (
                      <div style={{ height: 4, background: "white" }}></div>
                    )}
                    <Table hover responsive className="table-series">
                      <thead style={{ position: "sticky", zIndex: 1000 }}>
                        <tr>
                          {selectedQuickFilter ===
                            quickFiltersSeries.UNDER_REVIEW &&
                            selectScheduleActive && (
                              <td>
                                <Checkbox
                                  checked={
                                    series?.length ===
                                    selectedScheduleSeries?.length
                                  }
                                  indeterminate={
                                    series?.length !==
                                      selectedScheduleSeries?.length &&
                                    selectedScheduleSeries?.length !== 0
                                  }
                                  onChange={(e) => handleSelectVideo(e, "all")}
                                  style={{ width: 10, height: 10 }}
                                />
                              </td>
                            )}
                          <th className="table-rowseries-thumb">Thumbnail</th>
                          <th className="table-rowseries-id">Series ID</th>
                          <th className="large">Title</th>
                          {selectedQuickFilter ===
                            quickFilters.PROMOTED_SERIES && <th>Promoted</th>}
                          {selectedQuickFilter !== quickFiltersSeries.LIVE &&
                            selectedQuickFilter !==
                              quickFiltersSeries.SCHEDULED && (
                              <th className="large">
                                Posted on{" "}
                                {!searchName?.length > 0 && (
                                  <FontAwesomeIcon
                                    className="sort-icon"
                                    icon={
                                      sortKey === "published_on" && sortReverse
                                        ? faArrowDown
                                        : faArrowUp
                                    }
                                    onClick={() => handleSort("published_on")}
                                  />
                                )}
                              </th>
                            )}
                          {/* <th>Scheduled</th> */}
                          {/* {selectedQuickFilter ===
                            quickFilters.SCHEDULED && ( */}
                          <th className="large">
                            Scheduled{" "}
                            {!searchName?.length > 0 && (
                              <FontAwesomeIcon
                                className="sort-icon"
                                icon={
                                  sortKey === "scheduled_on"
                                    ? !sortReverse
                                      ? faArrowDown
                                      : faArrowUp
                                    : faArrowDown
                                }
                                onClick={() => handleSort("scheduled_on")}
                              />
                            )}
                          </th>
                          {/* )} */}
                          {quickFiltersSeries.LIVE === selectedQuickFilter && (
                            <th className="mid">
                              Approved On{" "}
                              {!searchName?.length > 0 && (
                                <FontAwesomeIcon
                                  className="sort-icon"
                                  icon={
                                    sortKey === "approved_on" && sortReverse
                                      ? faArrowDown
                                      : faArrowUp
                                  }
                                  onClick={() => handleSort("approved_on")}
                                />
                              )}
                            </th>
                          )}
                          <th className="mid">
                            Duration{" "}
                            {!searchName?.length > 0 && (
                              <FontAwesomeIcon
                                className="sort-icon"
                                icon={
                                  sortKey === "duration" && sortReverse
                                    ? faArrowDown
                                    : faArrowUp
                                }
                                onClick={() => handleSort("duration")}
                              />
                            )}
                          </th>
                          <th>Category</th>
                          <th>Creator</th>
                          {(selectedQuickFilter ===
                            quickFilters.PROMOTED_SERIES ||
                            selectedQuickFilter === quickFilters.SERIES) && (
                            <th className="mid">Status</th>
                          )}
                          {selectedQuickFilter === "LIVE" && (
                            <th>Performance</th>
                          )}

                          {/* // hiding it for viewer */}
                          {selectedQuickFilter !== "EXPIRED" &&
                            hasAccessToActions && <th>Actions</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {series?.length > 0 &&
                          series?.map((series, index) => {
                            return (
                              <tr
                                key={`${series.id}${index}`}
                                className="series-row"
                              >
                                {selectedQuickFilter ===
                                  quickFiltersSeries.UNDER_REVIEW &&
                                  selectScheduleActive && (
                                    <td>
                                      <Checkbox
                                        type="checkbox"
                                        value={series?.slug}
                                        checked={
                                          selectedScheduleSeries?.includes(
                                            series?.slug
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleSelectVideo(e, "")
                                        }
                                        className="checkbox-select"
                                        style={{ width: 10, height: 10 }}
                                      />
                                    </td>
                                  )}
                                <td>
                                  <div
                                    className="series-image"
                                    onClick={() =>
                                      series?.n_units > 0 &&
                                      playAllVideos(
                                        series?.n_units,
                                        series?.slug
                                      )
                                    }
                                  >
                                    <div className="img-hover-play">
                                      {series?.n_units > 0 && (
                                        <div className="play-all-videos-list">
                                          <FontAwesomeIcon
                                            icon={faPlayCircle}
                                          />
                                        </div>
                                      )}
                                      <img
                                        src={
                                          series.original_image
                                            ? series.original_image
                                            : placeholder
                                        }
                                        alt="avatar"
                                        className="table-rowserie-thumbnail series-avatar"
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className="table-rowseries-id">
                                  {series.id}
                                  <br />
                                  <span
                                    className="language_chip"
                                    style={{
                                      padding: "1px 7px 0px 7px",
                                      fontSize: 10,
                                      fontWeight: "normal",
                                      border:
                                        series?.language === "bn"
                                          ? "1px solid green"
                                          : "1px solid orange",
                                      color:
                                        series?.language === "bn"
                                          ? "green"
                                          : "orange",
                                    }}
                                  >
                                    {series?.language == "bn"
                                      ? "Bangla"
                                      : "Hindi"}
                                  </span>
                                </td>
                                <td className="table-rowseries-name">
                                  <div className="series-name">
                                    {remainingProps[
                                      "isOnCreatorProfilePage"
                                    ] ? (
                                      <Link
                                        from={`/creators/${remainingProps["creatorDetails"].id}`}
                                        to={`/series/${series.slug}`}
                                      >
                                        {series.display_title}
                                        {series.is_premium && (
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            color="#D1B000"
                                            size="1x"
                                          />
                                        )}
                                      </Link>
                                    ) : remainingProps["isOnShowPage"] ? (
                                      <Link
                                        from={`/shows-details/${remainingProps["show_data"]?.id}`}
                                        to={`/series/${series.slug}`}
                                      >
                                        {series.display_title}
                                        {series.is_premium && (
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            color="#D1B000"
                                            size="1x"
                                          />
                                        )}
                                      </Link>
                                    ) : (
                                      <Link to={`series/${series.slug}`}>
                                        {series.display_title}
                                        {series.is_premium && (
                                          <FontAwesomeIcon
                                            icon={faCheckCircle}
                                            color="#D1B000"
                                            size="1x"
                                          />
                                        )}
                                      </Link>
                                    )}
                                  </div>
                                </td>
                                {selectedQuickFilter !==
                                  quickFiltersSeries.LIVE &&
                                  selectedQuickFilter !==
                                    quickFiltersSeries.SCHEDULED && (
                                    <td className="table-series-ondate">
                                      {handleFormatDate({
                                        dateInString: series.published_on,
                                      })}
                                    </td>
                                  )}
                                {/* {selectedQuickFilter ===
                                      quickFilters.SCHEDULED && ( */}
                                <th
                                  style={{ fontSize: 12 }}
                                  className="table-series-ondate"
                                >
                                  {handleFormatDate({
                                    dateInString:
                                      series?.scheduled_info?.schedule_on,
                                  })}
                                </th>
                                {/* )} */}

                                {quickFiltersSeries.LIVE ===
                                  selectedQuickFilter &&
                                  selectedQuickFilter !==
                                    quickFiltersSeries.SCHEDULED && (
                                    <td
                                      style={{ fontSize: 12 }}
                                      className="table-series-ondate"
                                    >
                                      {handleFormatDate({
                                        dateInString:
                                          series?.approved_on &&
                                          series?.approved_on[0],
                                      })}
                                    </td>
                                  )}
                                {/* <td className="mid">{series.n_views}</td> */}
                                <td className="table-series-time">
                                  {getDuration(series?.duration_s)}
                                </td>
                                <td className="table-series-title">
                                  {series?.category?.title}
                                </td>
                                <td
                                  className="table-creator-name"
                                  id="HideScroll"
                                >
                                  {series?.creator?.name}
                                </td>
                                {/* Premium Series Tab */}
                                {selectedQuickFilter ===
                                  quickFilters.SERIES && (
                                  <td className="status">
                                    <div className="flex-status-chip">
                                      {/* Trail for changing the chip based on the live status */}
                                      <div
                                        className={series.status.toLowerCase()}
                                      >
                                        {series.status.toUpperCase()}
                                      </div>
                                    </div>

                                    {series?.is_news && (
                                      <div
                                        className="flex-status-chip"
                                        style={{ marginTop: "8px" }}
                                      >
                                        <div className="is_news">
                                          {series?.is_news && "Is News"}
                                        </div>
                                      </div>
                                    )}
                                  </td>
                                )}

                                {selectedQuickFilter === "LIVE" && (
                                  <div>
                                    {series.status == "live" ? (
                                      <td
                                        className="table-creator-cta"
                                        id="HideScroll"
                                      >
                                        <Button
                                          className="performance-cta-series"
                                          style={{ color: "blue" }}
                                          variant=""
                                          onClick={() =>
                                            handleAnalytics(series.id)
                                          }
                                        >
                                          {" "}
                                          <QueryStatsOutlined />{" "}
                                        </Button>
                                      </td>
                                    ) : (
                                      <td className="table-creator-name"></td>
                                    )}
                                  </div>
                                )}

                                {/* Action Button Dropdown*/}
                                {hasAccessToActions && (
                                  <td>
                                    {selectedQuickFilter !== "EXPIRED" && (
                                      <Dropdown className="w-100">
                                        <Dropdown.Toggle className="dropdown-action">
                                          <FontAwesomeIcon
                                            className="rejection-logs ml-1"
                                            icon={faEllipsisV}
                                            size="1x"
                                          />
                                        </Dropdown.Toggle>
                                        {/* Edit Promotion score button */}
                                        {selectedQuickFilter ===
                                          quickFilters.SCHEDULED &&
                                        (isAdmin || isSuperAdmin) ? (
                                          <Dropdown.Menu className="action-menu">
                                            <Dropdown.Item
                                              className="action-menu-item"
                                              onClick={() => {
                                                dispatch(
                                                  changeSeriesStatusAsync(
                                                    axiosPrivate,
                                                    series.slug,
                                                    "publish_now",
                                                    "",
                                                    setIsSeriesFetching,
                                                    isSeriesFetching,
                                                    setErrorMsg
                                                  )
                                                );
                                              }}
                                            >
                                              Make it live now
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="action-menu-item"
                                              onClick={() => {
                                                deleteSchedule(series?.slug);
                                              }}
                                            >
                                              Remove Scheduled
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                              className="action-menu-item"
                                              onClick={() => {
                                                setScheduledData(
                                                  series?.scheduled_info
                                                );
                                                setErrorForScheduling([]);
                                                setScheduleRowSlug(
                                                  series?.slug
                                                );
                                                setOpenScheduleModal(true);
                                              }}
                                            >
                                              Update Schedule
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        ) : (
                                          <Dropdown.Menu className="action-menu">
                                            {selectedQuickFilter !==
                                              "DELETED" && (
                                              <Dropdown.Item
                                                className="action-menu-item"
                                                href={`#/videos/${series.slug}`}
                                              >
                                                {/* <Link
                                              className="update-series-action-button"
                                              to={`/series/${series.slug}`}
                                            > */}
                                                Update Series
                                                {/* </Link> */}
                                              </Dropdown.Item>
                                            )}

                                            {/* <Divider className="divier-custom-color" /> */}
                                            {selectedQuickFilter !==
                                              "DELETED" && (
                                              <Dropdown.Item
                                                className="action-menu-item"
                                                onClick={() => {
                                                  dispatch(
                                                    fetchRejectionReason(
                                                      axiosPrivate,
                                                      series.slug,
                                                      setErrorMsg
                                                    )
                                                  );
                                                  setShowRejectionReasonsListForSeriesModal(
                                                    true
                                                  );
                                                }}
                                              >
                                                Rejection List
                                              </Dropdown.Item>
                                            )}

                                            {selectedQuickFilter ===
                                              "DELETED" && (
                                              <Dropdown.Item
                                                className="action-menu-item"
                                                onClick={() => {
                                                  dispatch(
                                                    changeSeriesStatusAsync(
                                                      axiosPrivate,
                                                      series.slug,
                                                      "unpublish",
                                                      "",
                                                      setIsSeriesFetching,
                                                      isSeriesFetching,
                                                      setErrorMsg
                                                    )
                                                  );
                                                }}
                                              >
                                                Move to draft
                                              </Dropdown.Item>
                                            )}

                                            {/* <Divider className="divier-custom-color" /> */}
                                            {(series.status ===
                                              quickFiltersSeriesValueMapping.DRAFT ||
                                              series.status ===
                                                quickFiltersSeriesValueMapping.CHANGES_REQUIRED) && (
                                              <>
                                                {/* <Divider className="divier-custom-color" /> */}
                                                <Dropdown.Item
                                                  className="action-menu-item"
                                                  onClick={() => {
                                                    dispatch(
                                                      changeSeriesStatusAsync(
                                                        axiosPrivate,
                                                        series.slug,
                                                        "publish",
                                                        "",
                                                        setIsSeriesFetching,
                                                        isSeriesFetching,
                                                        setErrorMsg
                                                      )
                                                    );
                                                  }}
                                                >
                                                  Send for Review
                                                </Dropdown.Item>
                                              </>
                                            )}

                                            {series.status ===
                                              quickFiltersSeriesValueMapping.UNDER_REVIEW &&
                                              (isAdmin || isSuperAdmin) && (
                                                <>
                                                  {/* <Divider className="divier-custom-color" /> */}
                                                  <Dropdown.Item
                                                    className="action-menu-item"
                                                    onClick={() => {
                                                      dispatch(
                                                        changeSeriesStatusAsync(
                                                          axiosPrivate,
                                                          series.slug,
                                                          "approved",
                                                          "",
                                                          setIsSeriesFetching,
                                                          isSeriesFetching,
                                                          setErrorMsg
                                                        )
                                                      );
                                                    }}
                                                  >
                                                    Make it Live
                                                  </Dropdown.Item>
                                                </>
                                              )}

                                            {(series.status ===
                                              quickFiltersSeriesValueMapping.UNDER_REVIEW ||
                                              series.status ===
                                                quickFiltersSeriesValueMapping.LIVE) &&
                                              (isAdmin || isSuperAdmin) && (
                                                <>
                                                  {/* <Divider className="divier-custom-color" /> */}
                                                  <Dropdown.Item
                                                    className="action-menu-item"
                                                    onClick={() => {
                                                      setShowRejectionReasonModal(
                                                        true
                                                      );
                                                      setRejectedSeriesSlug(
                                                        series.slug
                                                      );
                                                      // setSelectedSeries(series);
                                                    }}
                                                  >
                                                    Reject
                                                  </Dropdown.Item>
                                                </>
                                              )}

                                            {(series.status ===
                                              quickFiltersSeriesValueMapping.CHANGES_REQUIRED ||
                                              series.status ===
                                                quickFiltersSeriesValueMapping.UNDER_REVIEW) && (
                                              <>
                                                {/* <Divider className="divier-custom-color" /> */}
                                                <Dropdown.Item
                                                  className="action-menu-item"
                                                  onClick={() => {
                                                    dispatch(
                                                      changeSeriesStatusAsync(
                                                        axiosPrivate,
                                                        series.slug,
                                                        "unpublish",
                                                        "",
                                                        setIsSeriesFetching,
                                                        isSeriesFetching,
                                                        setErrorMsg
                                                      )
                                                    );
                                                  }}
                                                >
                                                  Move to draft
                                                </Dropdown.Item>
                                              </>
                                            )}
                                          </Dropdown.Menu>
                                        )}
                                      </Dropdown>
                                    )}
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                    {!isCreatorFetching && series?.length == 0 && (
                      <div className="img-nofound-wrapper">
                        <img
                          src={NoImg}
                          className="img-nofound"
                          alt="No data img"
                        />
                      </div>
                    )}
                  </Card>
                  <div className="pagination-table-bottom">
                    <Pagination
                      count={seriesPageData?.n_pages}
                      page={currentPageNumber}
                      onChange={(e, val) => handlePageChange(e, val)}
                      variant="outlined"
                      shape="rounded"
                    />
                  </div>
                </div>
                {/* CREATE FORM MODAL */}
                <Modal show={openCreateSeriesModal} centered>
                  <Modal.Header closeButton onHide={toggleCreate}>
                    <div className="modal-video-title">
                      Upload Episode/Series
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <CreateSeries
                      toggleCreate={toggleCreate}
                      handleFetchSeriesAfterCreated={
                        handleFetchSeriesAfterCreated
                      }
                    />
                  </Modal.Body>
                </Modal>
                {/* SHOW REJECTION REASON MODAL */}
                <Modal show={showRejectionReasonModal} centered>
                  <Modal.Header closeButton onHide={toggleRejection}>
                    <div className="modal-video-title">
                      Enter Rejection Reason
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <Form className="">
                      <Form.Group className="">
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Enter the reason for rejection..."
                          style={{ width: 450 }}
                          name="rejection_reason"
                          value={rejectionReasonField}
                          onChange={onRejectionFieldChange}
                        />
                      </Form.Group>
                      <Form.Group className=" d-flex justify-content-center">
                        <Button
                          variant="contained"
                          color="success"
                          className=""
                          onClick={rejectReasonSubmit}
                        >
                          Submit
                        </Button>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                </Modal>
                {/* Rejection data modal */}
                <Modal show={showRejectionReasonsListForSeriesModal} centered>
                  <Modal.Header closeButton onHide={toggleRejectionSeriesList}>
                    <div className="modal-video-title">
                      List of rejection reasons
                    </div>
                  </Modal.Header>
                  <Modal.Body>{renderRejectedReasonData()}</Modal.Body>
                </Modal>
                <Modal
                  // className="video-mod schedule-modal"
                  show={openScheduleModal}
                  centered
                >
                  <Modal.Header
                    closeButton
                    onHide={() => {
                      setOpenScheduleModal(!openScheduleModal);
                      setErrorForScheduling([]);
                      setScheduleRowSlug("");
                      setScheduledData({});
                    }}
                  >
                    <div className="modal-schedule-title">
                      <div className="modal-schedule-header">
                        Schedule Publish
                      </div>
                      <div className="modal-schedule-subtext">
                        India Standard Time
                      </div>
                    </div>
                  </Modal.Header>
                  {errorForScheduling?.length > 0 ? (
                    errorForScheduling?.map((data, i) => (
                      <div key={i}>
                        <ul className="modal-schedule-content-ul">
                          <li style={{ color: "red" }}>
                            {i + 1} .{data?.series_slug}: {data?.error_message}
                          </li>
                        </ul>
                      </div>
                    ))
                  ) : (
                    <div className="modal-schedule-content">
                      <ul className="modal-schedule-content-ul">
                        {schedularTimeList(os)?.map((dateinfo, i) => {
                          return (
                            <li
                              key={i}
                              className="modal-schedule-content-li"
                              onClick={() =>
                                setUpScheduleDate(
                                  dateinfo,
                                  "",
                                  scheduledData?.schedule_on
                                )
                              }
                            >
                              <div className="modal-date-title">
                                {dateinfo?.title}
                              </div>
                              <div>
                                <span className="modal-date-date">
                                  {getDateTimeFormat(dateinfo?.value)}
                                </span>
                                <span className="modal-date-time">
                                  {dateinfo?.time}
                                </span>
                              </div>
                            </li>
                          );
                        })}
                        {scheduledData?.schedule_on && (
                          <li
                            className={`modal-schedule-content-li selected-date`}
                          >
                            <div className="modal-date-title">
                              Scheduled Date
                            </div>
                            <div>
                              <span className="modal-date-date">
                                {handleFormatDate({
                                  dateInString: scheduledData?.schedule_on,
                                })}
                              </span>
                            </div>
                          </li>
                        )}
                      </ul>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateTimePicker"]}>
                          <DateTimePicker
                            label="Select time and Date"
                            disablePast
                            ampm={false}
                            onAccept={(newValue) =>
                              newValue &&
                              setUpScheduleDate(
                                newValue?.$d,
                                "custom",
                                scheduledData?.schedule_on
                              )
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  )}
                </Modal>
                <Modal
                  style={{ width: "120vw" }}
                  className="video-modal"
                  show={showVideo}
                  centered
                >
                  <Modal.Header closeButton onHide={handleVideoModal}>
                    <div className="modal-video-title">
                      {videoTitle?.length > 60
                        ? videoTitle?.substr(0, 60) + "..."
                        : videoTitle}
                    </div>
                  </Modal.Header>
                  <div
                    className="modal-video-player"
                    style={{ height: "100%" }}
                  >
                    <div className="icon">
                      {playIndex > 0 && (
                        <div className="prev-icon" onClick={playVideoPrev}>
                          <FontAwesomeIcon
                            size="2x"
                            className="icon"
                            icon={faChevronCircleLeft}
                          />
                        </div>
                      )}
                      {(playIndex < seriesVideos.length - 1 ||
                        hasMoreVideos) && (
                        <div
                          className="next-icon"
                          onClick={() => playVideoNext(-1, seriesVideos)}
                        >
                          <FontAwesomeIcon
                            size="2x"
                            className="icon"
                            icon={faChevronCircleRight}
                          />
                        </div>
                      )}
                    </div>
                    {showVideosLoading ? (
                      <div className="center">
                        <CircularProgress />
                      </div>
                    ) : videoSource ? (
                      <VideoPlayerCustom
                        showNextPrev={true}
                        controlProps={{
                          playIndex: playIndex,
                          seriesVideos: seriesVideos,
                          hasMoreVideos: hasMoreVideos,
                          playVideoPrev: playVideoPrev,
                          playVideoNext: (seriesVideos) =>
                            playVideoNext(-1, seriesVideos),
                        }}
                        src={videoSource}
                        controls={true}
                        muted={false}
                        autoplay={true}
                        onEnded={onVideoEnd}
                        onTimeUpdate={() => {}}
                        videoRef={videoRef}
                        currentTime={0}
                      />
                    ) : (
                      <div className="message">Source Not Found</div>
                    )}
                  </div>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  creators: selectAllSeries,
  series: selectAllSeries,
  isCreatorFetching: selectIsSeriesFetching,
  currentPageNumber: selectCurrentPageNumber,
  selectedSeriesFilters: selectSeriesFilters,
  selectedCreatorFilters: selectedCreatorFilters,
  hasMoreCreators: selectHasMore,
  configFilters: selectConfigFilters,
});

const mapDispatchToProps = (dispatch) => ({
  setSeriesFilters: (filters) => dispatch(setSeriesFilters(filters)),
  setHasMoreSeries: (hasMore) => dispatch(setHasMoreSeries(hasMore)),
  initializeSeries: () => dispatch(initializeSeriesData()),
});
const returnedFunction = connect(mapStateToProps, mapDispatchToProps);
const connectedComponent = returnedFunction(SeriesList);

export default connectedComponent;

import React from "react";
import { Button, Form, Row } from "react-bootstrap";
import { Box, Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectConfigFilters } from "../../redux/config/config.selector";

const FilterDrawer = ({
  openFilters,
  selectedFilters,
  setOpenFilters,
  handleSelectedFilter,
  handleClearAllFiltersFromURL,
  handleApplyFilters,
}) => {
  const history = useHistory();
  const configFilters = useSelector(selectConfigFilters);
  return (
    <div>
      {openFilters && (
        <Drawer
          anchor="right"
          open={openFilters}
          onClose={() => setOpenFilters(false)}
        >
          <Box
            sx={{ width: 350, overflow: "hidden", paddingLeft: 1 }}
            role="presentation"
          >
            <div className="filters-series box-filter-drawer">
              <div className="categories-list-series">
                <h5 className="filter-header">Categories</h5>
                <Form.Group
                  className="filters-list-series custom-drawer-list-series"
                  as={Row}
                >
                  {configFilters.categories?.map((item, index) => {
                    const filterValue = `${item.title}-${item.id}`;
                    return (
                      <Form.Check
                        key={item.id + `${index}`}
                        type="checkbox"
                        name="categoryFilter"
                        onChange={handleSelectedFilter}
                        value={filterValue}
                        label={item.title}
                        checked={selectedFilters?.includes(filterValue)}
                        className="form-col col-md-6 form-checkbox-align"
                      />
                    );
                  })}
                </Form.Group>
              </div>
            </div>
            <div className="action-button-filter flex gap-x-4">
              <Button
                variant="contained"
                onClick={() => {
                  handleClearAllFiltersFromURL({ history });
                  setOpenFilters(false);
                }}
                className="apply-button text-white"
              >
                Clear all
              </Button>

              {openFilters && (
                <Button
                  variant="contained"
                  onClick={handleApplyFilters}
                  className="apply-button text-white"
                >
                  Apply
                </Button>
              )}
            </div>
          </Box>
        </Drawer>
      )}
    </div>
  );
};

export default FilterDrawer;

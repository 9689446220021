import React from "react";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CloseIcon from "@mui/icons-material/Close";

const CustomSearch = ({
  searchQuery,
  placeHolder,
  wrapperClass,
  className,
  setSearchQuery,
  handleInputKey,
  handleClearInput,
  handleClickedOnSearchIcon,
}) => {
  return (
    <div className={wrapperClass}>
      <FontAwesomeIcon
        className="!text-[16px] text-gray-400 !font-normal ml-2"
        icon={faSearch}
        // color="black"
        onClick={handleClickedOnSearchIcon}
      />
      <input
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyPress={handleInputKey}
        value={searchQuery}
        placeholder={placeHolder}
        className={className}
      />
      <div className="w-[20px]">
        {searchQuery && (
          <button onClick={handleClearInput}>
            <CloseIcon className="!text-[20px] !font-bold -mt-1" />
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomSearch;

import React from "react";

const FeedbackIcon = ({ className }) => {
  return (
    <div>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M20 2.50781H4C2.9 2.50781 2 3.40781 2 4.50781V16.5078C2 17.6078 2.9 18.5078 4 18.5078H8V21.5078C8 22.1078 8.4 22.5078 9 22.5078H9.5C9.7 22.5078 10 22.4078 10.2 22.2078L13.9 18.5078H20C21.1 18.5078 22 17.6078 22 16.5078V4.50781C22 3.40781 21.1 2.50781 20 2.50781ZM11 13.5078H7V9.30781L8.3 6.50781H10.3L8.9 9.50781H11V13.5078ZM17 13.5078H13V9.30781L14.3 6.50781H16.3L14.9 9.50781H17V13.5078Z"
          fill="currentColor"
        />
        <path
          d="M20 3H4C2.9 3 2 3.9 2 5V17C2 18.1 2.9 19 4 19H8V22C8 22.6 8.4 23 9 23H9.5C9.7 23 10 22.9 10.2 22.7L13.9 19H20C21.1 19 22 18.1 22 17V5C22 3.9 21.1 3 20 3ZM11 14H7V9.8L8.3 7H10.3L8.9 10H11V14ZM17 14H13V9.8L14.3 7H16.3L14.9 10H17V14Z"
          //   fill="#605BFF"
        />
      </svg>
    </div>
  );
};

export default FeedbackIcon;
